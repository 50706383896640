import React, { PureComponent } from 'react'
import styles from './info.module.less'
import classnames from 'classnames'
import top1 from './image/1.png'
import top2 from './image/2.png'
import top3 from './image/3.png'
import areaIcon from './image/area.png'
import { InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
import { Statistic, StatisticInterface } from 'http-request/tokiot/statistic'
import { Title } from '../../title'
import { ColumnsItem, DataTable } from '../../data-table'
import { CommonModal } from 'components/modal/common-modal'
interface IProps {
  selectCommunity: typeof CommonParameter.BaseData[0],
  visible: boolean,
  onClose: () => void;
}
interface IState {
}
class CommunityInfo extends PureComponent<IProps, IState> {
  state: IState = {
  }

  componentDidMount() {
  }
  componentDidUpdate(prevProps: IProps) {
  }


  onClick = (index: number) => {

  }

  render() {
    const { selectCommunity, onClose, visible } = this.props;

    let getItem = (name: string, value: string) => {
      return (
        <div className={styles.row}>
          <div className={styles.name}>{name}</div>
          <div className={styles.value}>{value}</div>
        </div>
      )
    }

    return (
      <CommonModal
        centered
        mask={true}
        maskClosable={true}
        title={`小区详情-${selectCommunity.name}`}
        visible={visible}
        footer={null}
        width={`500rem`}
        onCancel={e => onClose()}
      >
        <div className={styles.main}>
          {getItem(`所在社区：`, selectCommunity.unityName.length > 0 ? selectCommunity.unityName : `无`)}
          {getItem(`类别：`, selectCommunity.type.length > 0 ? selectCommunity.type : `无`)}
          {getItem(`物业服务企业：`, selectCommunity.unit.length > 0 ? selectCommunity.unit : `无`)}
          {getItem(`是否成立业委会：`, selectCommunity.isMeet ? `是` : `否`)}
          {getItem(`备注：`, selectCommunity.remark.length > 0 ? selectCommunity.remark : `无`)}
        </div>
      </CommonModal>
    )
  }
}

export { CommunityInfo }
