import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import videoIcon from './image/video.png'
import { Title } from '../../title'
import { Info, InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
  deviceList: Array<InfoInterface.DeviceInfo>,
}
class VideoList extends PureComponent<IProps, IState>{
  state: IState = {
    deviceList: []
  }
  componentDidMount() {
    if (process.env.NODE_ENV === `development`) {
      // this.getdeviceList();
    } else {
      this.getdeviceList()
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { areaInfo } = this.props
    if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
      if (process.env.NODE_ENV === `development`) {
        // this.getdeviceList();
      } else {
        this.getdeviceList()
      }
    }
  }
 
  getdeviceList = () => {
    const { areaInfo } = this.props
    Info.DeviceList({ typeCode: `SPJK`, areaCode: areaInfo.code }).then(ret => {
      if (ret && ret.length > 0) {
        let deviceList = ret.filter(f1 => f1.state === `Normal`)
        deviceList = deviceList.filter((f1, index) => index < 4)
        this.setState({ deviceList })
      } else {
        this.setState({ deviceList: [] })
      }
    })
  }

  getTypeItem = (item: InfoInterface.DeviceInfo) => {
    return (
      <div className={styles.typeItem}>
        <div className={styles.video}>
          {item && item.sn && item.sn.length > 0 ? (
            <iframe
              className={styles.iframe}
              src={`https://iotapp.qztelecom.com/#/player/${JSON.stringify({
                sn: item.sn,
                playerType: `Flv`
              })}`}
            />
          ) : (
            ``
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.name} title={item.name}>
            {item.name}
          </div>
          <div className={styles.state}>
            <div className={styles.point}></div>
            <div className={styles.text}>{item.state}</div>
          </div>
        </div>
      </div>
    )
  }

  moreClick = () => {
    window.open(`/home/video`)
  }

  render() {
    const { deviceList } = this.state
    if (deviceList && deviceList.length > 0) {
      deviceList.forEach(f1 => (f1.state = `正常`))
    }
    if (!(deviceList && deviceList.length >= 4)) {
      let data = {
        name: `暂无数据`,
        state: `正常`,
        sn: ``,
        id: ``, createdTime: ``, updatedTime: ``, lng: ``,
        lat: ``, address: ``, installTime: ``, model: ``,
        deviceId: ``, typeId: ``, areaId: ``, unitId: ``, platformId: ``,
      }
      while (deviceList.length < 4) {
        deviceList.push(data)
      }
    }

    return (
      <div className={styles.videoListMain}>
        <div className={styles.title}>
          <Title className={styles.titleLeft} icon={videoIcon}>
            实时监控
          </Title>
          <div className={styles.titleRight} onClick={e => this.moreClick()}>
            更多
          </div>
        </div>
        <div className={styles.body}>
          {deviceList.map((f1, index) => this.getTypeItem(f1))}
        </div>
      </div>
    )
  }
}
export { VideoList }
