import React, { PureComponent } from "react";
import styles from "./areaMarker.module.less"
import classnames from "classnames"
import moment from "moment"
import { Statistic, StatisticInterface } from "http-request/tokiot/statistic";
import { StatisticCommon, StatisticCommonInterface } from "http-request/tokiot/statistic/common";
import { InfoInterface } from "http-request/tokiot/info";
import { CommonParameter } from "utils/common-parameter";
interface IProps {
    areaInfo: InfoInterface.AreaInfo,
}
interface IState {
    areaList: Array<StatisticInterface.AreaInfo>,
    alarmInfo?: StatisticCommonInterface.AlarmInfo,

}
class AreaMarker extends PureComponent<IProps, IState> {
    state: IState = {
        areaList: [],
    }
    componentDidMount() {
        this.getAlarmInfo();
        this.getAreaInfo();
    }

    componentDidUpdate(prevProps: IProps) {
        const { areaInfo } = this.props;
        if (CommonParameter.isChange(areaInfo, prevProps.areaInfo)) {
            this.getAlarmInfo();
            this.getAreaInfo();
        }
    }

    getAreaInfo = () => {
        const { areaInfo } = this.props;
        if (areaInfo.parentAreaInfo) {
            Statistic.Area({
                areaCode: areaInfo.parentAreaInfo.code,
                enableAlarm: true,
                enableDeviceType: true,
                enableUnitType: true,
            }).then(ret => {
                this.setState({ areaList: ret });
            })
        }

    }
    getAlarmInfo = () => {
        const { areaInfo } = this.props;
        let nowTime = moment();
        StatisticCommon.Alarm({
            areaCode: areaInfo.code,
            startTime: nowTime.format('YYYY-MM-DD'),
            endTime: nowTime.format('YYYY-MM-DD')
        }).then(ret => {
            this.setState({ alarmInfo: ret });
        })
    }

    render() {
        const { areaInfo } = this.props;
        const { areaList, alarmInfo } = this.state;
        let getItem = (name: string, value: string | number, className?: string) => {
            return (
                <div className={styles.item}>
                    <div className={styles.name}>{name}</div>
                    <div className={classnames(className, styles.value)}>{value}</div>
                </div>
            )
        }
        let unitTotal = 0;
        let deviceTotal = 0;
        let dTotal = 0;

        let normalTotal = 0;
        let alarmTotal = 0;
        let offlineTotal = 0;

        let nowAlarmTotal = 0;
        let nowHandleTotal = 0;
        let nowHandleRatio = 1;

        let allAlarmTotal = 0;
        let allHandleTotal = 0;
        let allHandleRatio = 1;

        if (areaList && areaList.length > 0) {
            let area = areaList.find(f1 => f1.code === areaInfo.code);
            if (area) {
                if (area.alarmStatistic) {
                    allAlarmTotal += Number(area.alarmStatistic.total);
                    allHandleTotal += Number(area.alarmStatistic.handleTotal);
                }
                if (area.unitTypeStatistic && area.unitTypeStatistic.length > 0) {
                    area.unitTypeStatistic.forEach(unit => {
                        unitTotal += Number(unit.total);
                    })
                }
                if (area.deviceTypeStatistic && area.deviceTypeStatistic.length > 0) {
                    area.deviceTypeStatistic.forEach(device => {
                        deviceTotal += Number(device.total);
                        normalTotal += Number(device.normalTotal);
                        alarmTotal += Number(device.alarmTotal);
                        offlineTotal += (Number(device.loseTotal) + Number(device.notActiveTotal) + Number(device.offlineTotal));
                    })
                }
            }
        }
        if (alarmInfo) {
            nowAlarmTotal += Number(alarmInfo.total);
            nowHandleTotal += Number(alarmInfo.handleTotal);
        }
        if (nowAlarmTotal > 0) {
            nowHandleRatio = nowHandleTotal / nowAlarmTotal;
        }
        if (allAlarmTotal > 0) {
            allHandleRatio = allHandleTotal / allAlarmTotal;
        }
        return (
            <div className={styles.markerMain}>
                <div className={styles.title}>
                    <div className={styles.text}>{areaInfo.name}</div>
                </div>
                <div className={styles.body}>
                    {getItem(`联网单位`, unitTotal)}
                    {getItem(`联网设备`, deviceTotal)}
                    {getItem(``, ``)}
                    {getItem(`正常设备`, normalTotal, styles.green)}
                    {getItem(`告警设备`, alarmTotal, styles.red)}
                    {getItem(`离线设备`, offlineTotal)}
                    {getItem(`今日告警`, nowAlarmTotal, styles.yellow)}
                    {getItem(`今日处理`, nowHandleTotal, styles.yellow)}
                    {getItem(`今日处警率`, `${Math.round(Number(nowHandleRatio) * 100)}%`, styles.yellow)}
                    {getItem(`累计告警`, allAlarmTotal, styles.yellow)}
                    {getItem(`累计处理`, allHandleTotal, styles.yellow)}
                    {getItem(`累计处警率`, `${Math.round(Number(allHandleRatio) * 100)}%`, styles.yellow)}
                </div>
            </div>
        );
    }
}

export { AreaMarker };

