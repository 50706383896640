import { ConfigProvider } from 'antd';
import React from 'react'
import styles from "./screen-layout.module.less";
import { SetHtmlFontSize } from "./set-html-font-size";
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import { Routes } from 'routes';
import { Info, InfoInterface } from 'http-request/tokiot/info';
import { AreaContext, UserContext } from 'context/context-react';
import { CommonParameter } from 'utils/common-parameter';
import { ClientVersion } from 'utils/constant';

class ScreenLayout extends React.PureComponent {

    componentDidMount() {
        console.info(`Version:${ClientVersion}`);

    }


    render() {
        SetHtmlFontSize();
        return (
            <div className={styles.main}>
                <ConfigProvider locale={locale}>
                    <Routes />
                </ConfigProvider>
            </div>
        )
    }
}
export { ScreenLayout }