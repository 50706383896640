import { HttpUtils } from 'utils/http-utils'
import * as StatisticCommonInterface from './interface'
namespace StatisticCommon {
    const BaseUrl = "/api/statistic/common/";
    export const Alarm = (params?: StatisticCommonInterface.AlarmParams): Promise<StatisticCommonInterface.AlarmInfo> => {
        return HttpUtils.Get(BaseUrl + `alarm`, params);
    };
    export const DeviceType = (params?: StatisticCommonInterface.DeviceTypeParams): Promise<Array<StatisticCommonInterface.DeviceTypeInfo>> => {
        return HttpUtils.Get(BaseUrl + `device-type`, params);
    };
    export const UnitType = (params?: StatisticCommonInterface.UnitTypeParams): Promise<Array<StatisticCommonInterface.UnitTypeInfo>> => {
        return HttpUtils.Get(BaseUrl + `unit-type`, params);
    };

}
export { StatisticCommon, StatisticCommonInterface };