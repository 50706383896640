import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import unitIcon from './image/unit.png'
import { InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
import { StatisticCommon, StatisticCommonInterface } from 'http-request/tokiot/statistic/common'
import { Title } from '../../title'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
}
class CommunityType extends PureComponent<IProps, IState>{
  state: IState = {
  }
  componentDidMount() {
  }

  componentDidUpdate(prevProps: IProps) {
    const { areaInfo } = this.props
    if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
    }
  }

  getTypeItem = (item: { typeName: string, total: number }, index: number, sum: number) => {
    let ratio = 0
    if (sum > 0) {
      ratio = Number(item.total) / sum
    }
    return (
      <div className={styles.typeItem}>
        <div className={styles.info}>
          <div className={styles.nameDiv}>
            <div
              className={classnames(styles.top, { [styles.yellow]: index < 3 })}
            >{`TOP${index + 1}`}</div>
            <div className={styles.name}>{item.typeName}</div>
          </div>
          <div className={styles.total}>{item.total}</div>
        </div>
        <div className={styles.lineDiv}>
          <div className={styles.line1}></div>
          <div
            className={styles.line2}
            style={{ width: `${ratio * 100}%` }}
          ></div>
        </div>
      </div>
    )
  }

  render() {
    const { BaseData } = CommonParameter
    let sum = 0;
    let unitTypeList: Array<{ typeName: string, total: number }> = [];

    BaseData.forEach(f1 => {
      let unit = unitTypeList.find(f2 => f2.typeName === f1.unityName);
      if (unit) {
        unit.total++;
      } else {
        unitTypeList.push({ typeName: f1.unityName, total: 1 });
      }
    })
    unitTypeList.sort((f1, f2) => Number(f2.total) - Number(f1.total))
    unitTypeList.forEach(f1 => (sum += Number(f1.total)))
    return (
      <div className={styles.uitTypeMain}>
        <Title icon={unitIcon}>社区列表</Title>
        <div className={styles.body}>
          {unitTypeList.map((f1, index) => this.getTypeItem(f1, index, sum))}
        </div>
      </div>
    )
  }
}

export { CommunityType }
