import React, { PureComponent } from 'react'
import styles from './index.module.less'

class VideoRight extends PureComponent {
  state = {}

  componentDidMount () {}

  getPlayerView = (gridMap, gridKey, gridCount) => {
    const device = gridMap[gridKey]
    let playerViewStyle
    switch (gridCount) {
      case 1:
        playerViewStyle = styles.playerView1
        break
      case 4:
        playerViewStyle = styles.playerView4
        break
      default:
        playerViewStyle = styles.playerView8
        break
    }
    if (device) {
      let deviceStatus
      let statusStyle
      switch (device.state) {
        case 'Normal':
          deviceStatus = '正常'
          statusStyle = styles.deviceStatusNormal
          break
        case 'Lose':
          deviceStatus = '失联'
          statusStyle = styles.deviceStatusLose
          break
        default:
          deviceStatus = '离线'
          statusStyle = styles.deviceStatusOffline
          break
      }
      return (
        <div key={gridKey + device.sn} className={playerViewStyle}>
          <div className={styles.playerTitleRoot}>
            <span className={statusStyle}>{deviceStatus}</span>
            <span className={styles.playerTitle}>{device.name}</span>
            <div
              className={styles.close}
              onClick={e => this.onCloseClick(gridKey)}
            />
          </div>
          {
            <iframe
              className={styles.iframe}
              src={`http://iotapp.qztelecom.com/#/player/${JSON.stringify({
                sn: device.sn,
                playerType: `Flv`
              })}`}
            />
          }
        </div>
      )
    }
    return <div className={playerViewStyle} />
  }

  onCloseClick = gridKey => {
    const { onCloseWindow } = this.props
    onCloseWindow(gridKey)
  }

  render () {
    const { gridMap, gridKeys, gridCount } = this.props
    return (
      <div className={styles.main}>
        {gridKeys.map(gridKey =>
          this.getPlayerView(gridMap, gridKey, gridCount)
        )}
      </div>
    )
  }
}

export default VideoRight
