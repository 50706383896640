import React, { PureComponent, ReactNode } from "react";
import styles from "./index.module.less"
import classnames from "classnames"
export interface ColumnsItem<T extends object = any> {
    key: string,
    name: string,
    width?: string,
    center?: boolean,
    render?: (text: string, item: T, index: number) => ReactNode
}

interface IProps<T extends object = any> {
    columns: Array<ColumnsItem<T>>;
    data: Array<T>;
    around?: boolean;
}
class DataTable<T extends object = any> extends PureComponent<IProps<T>> {
    state = {
    }
    componentDidMount() {
    }

    getTitle = (item: ColumnsItem<T>) => {
        let style: React.CSSProperties = {}
        if (item.width) {
            style.width = item.width;
        } else {
            style.width = `1px`;
            style.flexGrow = 1;
        }

        return (
            <div
                className={classnames(styles.titleDiv, { [styles.hCenter]: item.center })}
                style={style}
            >
                {item.name}
            </div>
        )
    }
    getRow = (item: any, index: number) => {
        const { columns, data } = this.props
        if (!(columns && columns.length > 0)) return ``;
        return (
            <div className={styles.row}>
                {columns.map(f1 => {
                    let dataitem = ``;
                    if (item[f1.key]) {
                        dataitem = item[f1.key];
                    }
                    return this.getColumn(f1, dataitem, item, index);
                })}
            </div>
        )

    }

    getColumn = (columnsItem: ColumnsItem<T>, dataitem: string, item: T, index: number) => {
        let style: React.CSSProperties = {}
        if (columnsItem.width) {
            style.width = columnsItem.width;
        } else {
            style.width = `1px`;
            style.flexGrow = 1;
        }
        let data: ReactNode = undefined;
        if (columnsItem.render) {
            data = columnsItem.render(dataitem, item, index);
        } else {
            data = dataitem;
        }
        return (
            <div
                className={classnames(styles.column, { [styles.hCenter]: columnsItem.center })}
                style={style}
            >
                {data}
            </div>
        )
    }

    render() {
        const { columns, data, around } = this.props
        if (!(columns && columns.length > 0)) return ``;
        return (
            <div className={styles.dataTableMain} >
                <div className={styles.titleList} >
                    {columns.map(f1 => this.getTitle(f1))}
                </div>
                <div className={classnames(styles.body, { [styles.around]: around })} >
                    {data.map((f1, index) => this.getRow(f1, index))}
                </div>
            </div>
        );
    }
}

export { DataTable };

