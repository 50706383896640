import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import alarm1 from './image/alarm1.jpg'
import alarm2 from './image/alarm2.jpg'
import alarm3 from './image/alarm3.jpg'
import totalBg from './image/totalBg.png'
import buttonD from './image/buttonD.png'
import buttonH from './image/buttonH.png'
import AlarmCountWebSocket from './AlarmCountWebSocket'
import moment from 'moment'
import alarmIcon from './image/alarm.png'
import { Statistic } from 'http-request/tokiot/statistic'
import { Title } from 'routes/home/components/title'
import { InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
import { StatisticCommon, StatisticCommonInterface } from 'http-request/tokiot/statistic/common'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
  alarmState?: StatisticCommonInterface.AlarmInfo,
  isToday: boolean,
}
class AlarmType extends PureComponent<IProps, IState>{
  state: IState = {
    isToday: true
  }

  componentDidMount() {
    this.getAlarmType()
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const { areaInfo } = this.props
    if (prevState.isToday !== this.state.isToday || CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
      this.getAlarmType()
    }
  }
  getAlarmType = () => {
    const { isToday } = this.state
    const { areaInfo } = this.props
    let nowTime = moment()
    StatisticCommon.Alarm({
      areaCode: areaInfo.code,
      startTime: nowTime.format('YYYY-MM-DD'),
      endTime: nowTime.format('YYYY-MM-DD'),
    }).then(ret => {
      if (ret) {
        this.setState({ alarmState: ret })
      }
    })
  }

  getAlarmItem = (name: string, total: string, src: string) => {
    return (
      <div className={styles.alarmItem}>
        <img className={styles.image} src={src} alt="" />
        <div className={styles.info}>
          <div className={styles.text}>{name}</div>
          <div className={styles.total}>{total}</div>
        </div>
      </div>
    )
  }

  render() {
    const { alarmState, isToday } = this.state
    if (!alarmState) return ``;
    return (
      <div className={styles.alarmTypeMain}>
        <div className={styles.title}>
          <Title className={styles.titleInfo} icon={alarmIcon}>
            警情统计
          </Title>
          <div className={styles.buttons}>
            <div
              className={classnames(styles.button, {
                [styles.buttonD]: !isToday
              })}
              onClick={e => this.setState({ isToday: true })}
            >
              <img className={styles.image} src={isToday ? buttonH : buttonD} alt="" />
              今日
            </div>
            <div
              className={classnames(styles.button, {
                [styles.buttonD]: isToday
              })}
              onClick={e => this.setState({ isToday: false })}
            >
              <img className={styles.image} src={isToday ? buttonD : buttonH} alt="" />
              累计
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.alarmList}>
            {this.getAlarmItem(`一般告警`, alarmState.generalTotal, alarm3)}
            {this.getAlarmItem(`严重告警`, alarmState.seriousTotal, alarm2)}
            {this.getAlarmItem(`紧急告警`, alarmState.urgentTotal, alarm1)}
          </div>
          <div className={styles.alarmTotal}>
            <div className={styles.roundBg}>
              <img className={styles.image} src={totalBg} alt="" />
              <div className={styles.roundDiv}>
                <div className={styles.roundDiv2}>
                  <div className={styles.round1}></div>
                  <div className={styles.round2}></div>
                  <div className={styles.round3}></div>
                  <div className={styles.round4}></div>
                </div>
                {/* <AlarmChart /> */}
                <div className={styles.text}>
                  {isToday ? `今日告警` : `累计告警`}
                </div>
                <div className={styles.total}>
                  {isToday ? (
                    <AlarmCountWebSocket defaultValue={alarmState.total} />
                  ) : (
                    Number(alarmState.total).toLocaleString()
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.stateItem}>
              <div className={styles.name}>
                <div className={styles.icon}></div>
                <div className={styles.text}>{`已处理`}</div>
              </div>
              <div className={styles.total}>{alarmState.handleTotal}</div>
            </div>
            <div className={styles.stateItem}>
              <div className={styles.name}>
                <div className={classnames(styles.icon, styles.redIcon)}></div>
                <div className={styles.text}>{`待处理`}</div>
              </div>
              <div className={classnames(styles.total, styles.red)}>
                {alarmState.unHandleTotal}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export { AlarmType }
