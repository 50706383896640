import React, { PureComponent } from "react";
import styles from "./index.module.less"
import classnames from "classnames"
import select from "./image/select.png"
import { InfoInterface } from "http-request/tokiot/info";
import { CommonParameter } from "utils/common-parameter";
interface IProps {
    areaInfo: InfoInterface.AreaInfo,
}
class Address extends PureComponent<IProps>{

    state = {
    }

    componentDidMount() {
    }

    onClick = () => {
        const { areaInfo } = this.props;
        if (areaInfo.parentAreaInfo) {
            CommonParameter.SetSelectArea && CommonParameter.SetSelectArea(areaInfo.parentAreaInfo);
        }
    };

    render() {
        const { areaInfo } = this.props;
        return (
            <div className={styles.addressMain} >
                <div className={styles.address} >
                    <div className={styles.addressBg} ></div>
                    {areaInfo.parentAreaInfo ?
                        <React.Fragment>
                            <div className={styles.text} onClick={e => this.onClick()}>{areaInfo.parentAreaInfo.name}</div>
                            <div className={styles.divider}>/</div>
                        </React.Fragment>
                        : ``}

                    <div className={styles.textSelect} >
                        <div className={styles.text} >{areaInfo.name}</div>
                        <div className={styles.imageDiv} >
                            <img className={styles.image} src={select} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export { Address };

