import React, { PureComponent } from "react";
import styles from "./index.module.less"
import classnames from "classnames"
import { InfoInterface } from "http-request/tokiot/info";
import { DeviceType } from "./device-type";
import { AlarmType } from "./alarm-type";
import { UnitRank } from "./unit-rank";
import { MenuType } from "routes/home";
interface IProps {
    className: string,
    userInfo: InfoInterface.UserInfo,
    areaInfo: InfoInterface.AreaInfo,
    features: MenuType,
}
class Home extends PureComponent<IProps>{
    state = {
    }
    componentDidMount() {
    }

    render() {
        const { className, areaInfo, features } = this.props;
        let param = { areaInfo, features }
        return (
            <div className={classnames(className, styles.leftMain)} >
                <DeviceType {...param} />
                <AlarmType  {...param} />
                <UnitRank {...param} />
            </div>
        );
    }
}

export default Home;

