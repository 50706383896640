import React, { PureComponent } from "react";
import styles from "./index.module.less"
import classnames from "classnames"
import { MapChart } from "./map-chart"
import { InfoInterface } from "http-request/tokiot/info";
import { AllTotal } from "./all-total";
import { DeviceStateTotal } from "./device-state-total";
import { Address } from "./address";
import { AMapMap } from "./amap-map";
import { MenuType } from "routes/home";
import { AllTotalTemp } from "./all-total-temp";
import { AmapMapTemp } from "./amap-map-temp";

interface IProps {
    className: string,
    userInfo: InfoInterface.UserInfo,
    areaInfo: InfoInterface.AreaInfo,
    features: MenuType,
}
class Center extends PureComponent<IProps> {

    state = {
    }

    componentDidMount() {

    }

    render() {
        const { className, areaInfo, features } = this.props;
        let param = { areaInfo, features }
        return (
            <div className={classnames(className, styles.centerMain)} >
                {features === `lakeside` ?
                    <AllTotalTemp {...param} />
                    :
                    <AllTotal {...param} />
                }
                <DeviceStateTotal {...param} />
                <Address {...param} />
                {/* <MapChart {...param} /> */}
                {features === `lakeside` ?
                    <AmapMapTemp {...param} />
                    :
                    <AMapMap {...param} />
                }
            </div>
        );
    }
}

export default Center;

