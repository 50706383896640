import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import unitIcon from './image/unit.png'
import deviceIcon from './image/device.png'
import orgIcon from './image/org.png'
import { StatisticCommon, StatisticCommonInterface } from 'http-request/tokiot/statistic/common'
import { InfoInterface } from 'http-request/tokiot/info'
import { Statistic, StatisticInterface } from 'http-request/tokiot/statistic'
import { CommonParameter } from 'utils/common-parameter'
import { Title } from '../../title'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
  unitTypeList: Array<StatisticCommonInterface.UnitTypeInfo>,
  deviceTypeList: Array<StatisticCommonInterface.DeviceTypeInfo>,
  unitList: Array<StatisticInterface.OrganInfo>,
}
class AllTotal extends PureComponent<IProps, IState> {
  state: IState = {
    unitTypeList: [],
    deviceTypeList: [],
    unitList: [],
  }

  componentDidMount() {
    this.getDeviceType();
    this.getUnitType();
    this.getUnitRank();
  }

  componentDidUpdate(prevProps: IProps) {
    const { areaInfo } = this.props
    if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
      this.getDeviceType();
      this.getUnitType();
      this.getUnitRank();
    }
  }

  getUnitRank = () => {
    Statistic.Organ({}).then(ret => {
      if (ret && ret.length > 0) {
        this.setState({ unitList: ret })
      } else {
        this.setState({ unitList: [] })
      }
    })
  }

  getUnitType = () => {
    const { areaInfo } = this.props
    StatisticCommon.UnitType({ areaCode: areaInfo.code }).then(ret => {
      if (ret && ret.length > 0) {
        this.setState({ unitTypeList: ret })
      } else {
        this.setState({ unitTypeList: [] })
      }
    })
  }

  getDeviceType = () => {
    const { areaInfo } = this.props
    StatisticCommon.DeviceType({ areaCode: areaInfo.code }).then(ret => {
      if (ret && ret.length > 0) {
        this.setState({ deviceTypeList: ret })
      } else {
        this.setState({ deviceTypeList: [] })
      }
    })
  }

  getItem = (item: any) => {
    return (
      <div className={styles.info}>
        <div className={styles.titleDiv}>
          <div className={styles.titleBg}></div>
          <Title className={styles.title} icon={item.icon}>
            {item.name}
          </Title>
        </div>
        <div className={styles.total}>
          {Number(item.total).toLocaleString()}
        </div>
      </div>
    )
  }

  render() {
    const { deviceTypeList, unitTypeList, unitList } = this.state
    let data = [
      {
        name: `联网单位`,
        total: 0,
        icon: unitIcon
      },
      {
        name: `联网设备`,
        total: 0,
        icon: deviceIcon
      },
      {
        name: `运营机构`,
        total: unitList && unitList.length > 0 ? unitList.length : 0,
        icon: orgIcon
      }
    ]

    if (deviceTypeList && deviceTypeList.length > 0) {
      deviceTypeList.forEach(f1 => {
        data[1].total += Number(f1.total)
      })
    }

    if (unitTypeList && unitTypeList.length > 0) {
      unitTypeList.forEach(f1 => {
        data[0].total += Number(f1.total)
      })
    }

    return (
      <div className={styles.allTtotalMain}>
        {data.map(f1 => this.getItem(f1))}
      </div>
    )
  }
}

export { AllTotal }
