import { HttpUtils } from 'utils/http-utils'
import * as InfoInterface from './interface'
namespace Info {
    const BaseUrl = "/api/info/";
    export const AreaSyncLoad = (params?: InfoInterface.AreaSyncLoadParams): Promise<Array<InfoInterface.AreaSyncLoadInfo>> => {
        return HttpUtils.Get(BaseUrl + `area-sync-load`, params);
    };
    export const DeviceList = (params?: InfoInterface.DeviceListParams): Promise<Array<InfoInterface.DeviceInfo>> => {
        return HttpUtils.Get(BaseUrl + `device-list`, params);
    };
    export const User = (params?: {}): Promise<InfoInterface.UserInfo> => {
        return HttpUtils.Get(BaseUrl + `user`, params);
    };

}
export { Info, InfoInterface };