import React from "react";
import { Button, Form, Input, message } from "antd";
import { Oauth } from "http-request";
import styles from "./index.module.less";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import $$ from 'cmn-utils';
interface LonginInfo {
    access_token: string;
    token_type: string;
    expires_in: string;
    scope: string;
}
class Login extends React.PureComponent {
    componentDidMount() {
        $$.clearStore();
    }
    onFinish = (values: any) => {
        Oauth.Login({
            username: values.username,
            password: values.password,
        }).then(ret => {
            if (ret) {
                let info = ret as LonginInfo;
                if (ret.error) {
                    message.error(ret.error_description)
                    return;
                }
                $$.setStore("headers", {
                    Username: values.username,
                    AccessToken: info.access_token,
                    Authorization: info.token_type + " " + info.access_token,
                });
                window.location.href = '/home/index'
            }
        })

    }

    render() {
        const { width: windowsWidth, height: windowsHeight } = window.screen;
        return (
            <div className={styles.main}
                style={{
                    width: windowsWidth,
                    height: windowsHeight,
                    minWidth: windowsWidth,
                    minHeight: windowsHeight,
                }}
            >
                <Form className={styles.form}
                    name="normal-login"
                    layout='horizontal'
                    size='large'
                    onFinish={this.onFinish}
                >
                    <div style={{ marginBottom: 30, display: 'flex', justifyContent: 'center', fontSize: 32, fontWeight: 600, color: 'rgb(31,173,244)' }}>智 汇 聚                    </div>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: '请输入账号',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账号" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="密码"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size='large'
                            style={{ width: 'calc(100% - 30px)', marginLeft: 15, borderRadius: 60 }}
                        >
                            登 录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )

    }
}
export { Login }