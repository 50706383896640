import WebSocketCom from "components/WebSocketCom";
import React, { PureComponent } from "react";
interface IProps {
    defaultValue: string;
}
interface IState {
    alarmCount?: { total: number };
}

class AlarmCountWebSocket extends PureComponent<IProps, IState> {
    state: IState = {
        alarmCount: undefined
    }

    onMessage = (message: any) => {
        message = JSON.parse(message)
        switch (message.type) {
            case "TODAY_ALARM_COUNT":
                this.setState({ alarmCount: message.data as { total: number } });
                break;
            default:
                console.error("推送内容未解析", message)
        }

    }

    render() {
        const { alarmCount } = this.state;
        const { defaultValue } = this.props;
        let value = defaultValue;
        if (alarmCount && alarmCount.total) {
            value = alarmCount.total.toString();
        }
        return <React.Fragment>
            {/* <WebSocketCom url={"/websocket/root"} onMessage={this.onMessage} /> */}
            {Number(value).toLocaleString()}
        </React.Fragment >;
    }
}

export default AlarmCountWebSocket;