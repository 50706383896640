import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import unitIcon from './image/unit.png'
import { InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
import { StatisticCommon, StatisticCommonInterface } from 'http-request/tokiot/statistic/common'
import { Title } from '../../title'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
  unitTypeList: Array<StatisticCommonInterface.UnitTypeInfo>,
}
class UnitType extends PureComponent<IProps, IState>{
  state: IState = {
    unitTypeList: []
  }
  componentDidMount() {
    this.getUnitType()
  }

  componentDidUpdate(prevProps: IProps) {
    const { areaInfo } = this.props
    if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
      this.getUnitType()
    }
  }

  getUnitType = () => {
    const { areaInfo } = this.props
    StatisticCommon.UnitType({ areaCode: areaInfo.code }).then(ret => {
      if (ret && ret.length > 0) {
        ret.sort((f1, f2) => Number(f2.total) - Number(f1.total))
        this.setState({ unitTypeList: ret })
      } else {
        this.setState({ unitTypeList: [] })
      }
    })
  }
  getTypeItem = (item: StatisticCommonInterface.UnitTypeInfo, index: number, sum: number) => {
    let ratio = 0
    if (sum > 0) {
      ratio = Number(item.total) / sum
    }
    return (
      <div className={styles.typeItem}>
        <div className={styles.info}>
          <div className={styles.nameDiv}>
            <div
              className={classnames(styles.top, { [styles.yellow]: index < 3 })}
            >{`TOP${index + 1}`}</div>
            <div className={styles.name}>{item.typeName}</div>
          </div>
          <div className={styles.total}>{item.total}</div>
        </div>
        <div className={styles.lineDiv}>
          <div className={styles.line1}></div>
          <div
            className={styles.line2}
            style={{ width: `${ratio * 100}%` }}
          ></div>
        </div>
      </div>
    )
  }

  render() {
    const { unitTypeList } = this.state
    let sum = 0
    unitTypeList.forEach(f1 => (sum += Number(f1.total)))
    return (
      <div className={styles.uitTypeMain}>
        <Title icon={unitIcon}>社会单位类型排名</Title>
        <div className={styles.body}>
          {unitTypeList.map((f1, index) => this.getTypeItem(f1, index, sum))}
        </div>
      </div>
    )
  }
}

export { UnitType }
