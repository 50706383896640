import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import unitIcon from './image/unit.png'
import deviceIcon from './image/device.png'
import orgIcon from './image/org.png'
import { StatisticCommon, StatisticCommonInterface } from 'http-request/tokiot/statistic/common'
import { InfoInterface } from 'http-request/tokiot/info'
import { Statistic, StatisticInterface } from 'http-request/tokiot/statistic'
import { CommonParameter } from 'utils/common-parameter'
import { Title } from '../../title'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
}
class AllTotalTemp extends PureComponent<IProps, IState> {
  state: IState = {
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps: IProps) {
    const { areaInfo } = this.props
  }

  getItem = (item: any) => {
    return (
      <div className={styles.info}>
        <div className={styles.titleDiv}>
          <div className={styles.titleBg}></div>
          <Title className={styles.title} icon={item.icon}>
            {item.name}
          </Title>
        </div>
        <div className={styles.total}>
          {Number(item.total).toLocaleString()}
        </div>
      </div>
    )
  }

  render() {
    let data = [
      {
        name: `联网社区`,
        total: 10,
        icon: unitIcon
      },
      {
        name: `联网小区`,
        total: 144,
        icon: orgIcon
      },
      {
        name: `沿街商铺`,
        total: 3000,
        icon: deviceIcon
      },
      
    ]

    return (
      <div className={styles.allTtotalMain}>
        {data.map(f1 => this.getItem(f1))}
      </div>
    )
  }
}

export { AllTotalTemp }
