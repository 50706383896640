import React, { PureComponent } from "react";
import styles from "./index.module.less"
import topBg from "./image/topBg.png"
import buttonD from "./image/buttonD.png"
import buttonH from "./image/buttonH.png"
import classnames from "classnames"
import Time from "./time"
import $$ from "cmn-utils";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MenuType } from "routes/home";
interface IProps {
    className?: string,
}
interface IState {
    selelcButton: string,
}
class TopClass extends PureComponent<RouteComponentProps<{ features: MenuType }, {}, {}> & IProps, IState>{
    state = {
        selelcButton: `home`,
    }
    componentDidMount() {

    }

    buttonClick = (key: string) => {
        const { history } = this.props;
        history.push(`/home/${key}`);
        this.setState({ selelcButton: key });
    }

    getButton = (item: { key: string, name: string, }) => {
        const { selelcButton } = this.state;
        return (
            <div className={selelcButton === item.key ? styles.buttonSelect : styles.button} onClick={e => this.buttonClick(item.key)}>
                <span className={styles.text}>{item.name}</span>
            </div>
        )
    }

    getUserButton = (item: { className: string, name: string, onClick?: () => void, }) => {
        return (
            <div className={styles.button} onClick={e => item.onClick && item.onClick()}>
                <div className={styles[item.className]}></div>
                <span className={styles.text}>{item.name}</span>
            </div>
        )
    }

    controlClick = () => {
        let headers = $$.getStore("headers");
        if (headers && headers.AccessToken && headers.AccessToken.length > 0) {
            let accessToken = headers.AccessToken;
            window.open(`http://iotcity.qztelecom.com/admin/default/index?access_token=${accessToken}`);
        }
    }
    exitClick = () => {
        window.location.href = '/account/login'
    }
    render() {
        const { className } = this.props

        let buttonList = [
            {
                key: `index`,
                name: `智慧聚`,
            },
            {
                key: `lakeside`,
                name: `智慧湖滨`,
            },
            {
                key: `governance`,
                name: ``,
            },
            {
                key: `attemper`,
                name: ``,
            },
            {
                key: `judge`,
                name: ``,
            },
        ]

        let userButtons = [
            {
                className: `message`,
                name: `消息通知`,
            },
            {
                className: `control`,
                name: `后台管理`,
                onClick: this.controlClick,
            },
            {
                className: `exit`,
                name: `退出`,
                onClick: this.exitClick,
            },
        ]

        return (
            <div className={classnames(className, styles.topMain)} >
                <img src={topBg} className={styles.image} alt="" />
                <div className={styles.body} >
                    <div className={styles.buttonList} >
                        {buttonList.map(f1 => this.getButton(f1))}
                    </div>
                    <div className={styles.right} >
                        <Time />
                        <div className={styles.divider} ></div>
                        <div className={styles.userButtons} >
                            {userButtons.map(f1 => this.getUserButton(f1))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

let Top = withRouter(TopClass);
export { Top };

