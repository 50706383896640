import React, { ReactNode } from 'react';
import * as AntdIcon from '@ant-design/icons';
import { IotCityIcon } from './iot-cityIcon'
import { IconBaseProps } from '@ant-design/icons/lib/components/Icon';
interface IProps {
    iot?: boolean;
}

class Icon extends React.PureComponent<IProps & IconBaseProps> {
    render() {
        const { iot, type, ...props } = this.props;
        let antIcon: { [key: string]: any } = AntdIcon as { [key: string]: ReactNode };
        if (type) {
            if (iot) {
                return (<IotCityIcon type={type} {...props} />)
            } else {
                if (antIcon[type]) {
                    return React.createElement(antIcon[type], { ...props })
                } else {
                    return ``;
                }
            }
        } else {
            return ``;
        }
    }
}

export { Icon };
