import { HttpUtils } from 'utils/http-utils'
namespace Oauth {
    const BaseUrl = "/oauth/";
    export const Login = (params?: { username: string, password: string }): Promise<any> => {
        let data = {
            grant_type: `password`, scope: `server`, client_id: `cli_465f93edbedd`, client_secret: `88888888888888888888`
        }
        return HttpUtils.Post(BaseUrl + `token`, { ...params, ...data }, true);
    };


}
export { Oauth };