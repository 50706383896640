import React, { PureComponent } from "react";
import styles from "./index.module.less"
import classnames from "classnames"
import { InfoInterface } from "http-request/tokiot/info";
import { UnitType } from "./unit-type";
import { VideoList } from "./video-list";
import { AreaRank } from "./area-rank";
import { MenuType } from "routes/home";
import { CommunityType } from "./community-type";
import { CommunityRank } from "./community-rank";
interface IProps {
    className: string,
    userInfo: InfoInterface.UserInfo,
    areaInfo: InfoInterface.AreaInfo,
    features: MenuType,
}
class Right extends PureComponent<IProps> {

    state = {
    }

    componentDidMount() {
    }



    render() {
        const { className, areaInfo, features } = this.props;
        let param = {
            areaInfo
        }
        return (
            <div className={classnames(className, styles.rightMain)} >
                {features === `lakeside` ?
                    <React.Fragment>
                        <CommunityType {...param} />
                        <VideoList  {...param} />
                        <CommunityRank {...param} />
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <UnitType {...param} />
                        <VideoList  {...param} />
                        <AreaRank {...param} />
                    </React.Fragment>
                }

            </div>
        );
    }
}

export default Right;

