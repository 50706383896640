import { HttpUtils } from 'utils/http-utils'
import * as StatisticInterface from './interface'
namespace Statistic {
    const BaseUrl = "/api/statistic/";
    export const Area = (params?: StatisticInterface.AreaParams): Promise<Array<StatisticInterface.AreaInfo>> => {
        return HttpUtils.Get(BaseUrl + `area`, params);
    };
    export const Organ = (params?: StatisticInterface.OrganParams): Promise<Array<StatisticInterface.OrganInfo>> => {
        return HttpUtils.Get(BaseUrl + `organ`, params);
    };
}
export { Statistic, StatisticInterface };