import { ButtonProps } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styles from './common-button.module.less';
interface IProps {
    type?: `default` | `primary`,
    hover?: boolean
}
class CommonButton extends React.PureComponent<IProps & ButtonProps> {
    static defaultProps: IProps & ButtonProps = {
        type: `primary`,
        hover: true,
    }

    componentDidMount() {
    }

    render() {
        const { className, children, type, hover, disabled, onClick, ...props } = this.props;
        let buttonClass: string = ``;
        let hoverClass: string = ``;
        let disabledClass: string = ``;
        switch (type) {
            case `default`:
                buttonClass = styles.default;
                disabledClass = styles.defaultDisabled
                if (hover)
                    hoverClass = styles.defaultHover;
                break;
            default:
                buttonClass = styles.primary;
                disabledClass = styles.primaryDisabled
                if (hover)
                    hoverClass = styles.primaryHover;
                break;
        }

        return (
            <div className={classNames(className, buttonClass, hoverClass, { [disabledClass]: disabled })} onClick={disabled ? () => { } : onClick} {...props} >
                {children}
            </div>
        );
    }
}
export { CommonButton };