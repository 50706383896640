import { InfoInterface } from "http-request/tokiot/info";
import React from "react";


class ContextClass<T> {
    private context!: React.Context<T>;
    constructor(type: React.Context<T>) {
        this.context = type;
    }

    public get Context(): React.Context<T> {
        return this.context;
    }

}

class UserContextClass extends ContextClass<InfoInterface.UserInfo>{
    constructor() {
        super(React.createContext<InfoInterface.UserInfo>({ email: ``, realName: ``, tel: ``, username: `` }))
    }

}

class AreaContextClass extends ContextClass<InfoInterface.AreaInfo>{
    constructor() {
        super(React.createContext<InfoInterface.AreaInfo>({ id: ``, code: ``, level: ``, name: ``, sort: `` }))
    }
}

export const UserContext = new UserContextClass();
export const AreaContext = new AreaContextClass();