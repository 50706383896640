import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import top1 from './image/1.png'
import top2 from './image/2.png'
import top3 from './image/3.png'
import areaIcon from './image/area.png'
import { InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
import { Statistic, StatisticInterface } from 'http-request/tokiot/statistic'
import { Title } from '../../title'
import { ColumnsItem, DataTable } from '../../data-table'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
  areaList: Array<StatisticInterface.AreaInfo & { deviceTotle: number }>,
  httpIndex: number,
}
class AreaRank extends PureComponent<IProps, IState> {
  state: IState = {
    areaList: [],
    httpIndex: 0
  }

  componentDidMount() {
    this.getAreaRank()
  }
  componentDidUpdate(prevProps: IProps) {
    const { areaInfo } = this.props
    if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
      this.getAreaRank()
    }
  }

  getAreaRank = () => {
    const { areaInfo } = this.props
    Statistic.Area({
      areaCode: areaInfo.code,
      enableAlarm: true,
      enableDeviceType: true
    }).then(ret => {
      if (ret && ret.length > 0) {
        let retList = ret.map(record => {
          let deviceTotle = 0
          if (
            record.deviceTypeStatistic &&
            record.deviceTypeStatistic.length > 0
          ) {
            record.deviceTypeStatistic.forEach(
              f1 => (deviceTotle += Number(f1.total))
            )
          }
          return { ...record, deviceTotle };
        })
        retList.sort((f1, f2) => f2.deviceTotle - f1.deviceTotle)
        this.setState({ areaList: retList })
      } else {
        this.setState({ areaList: [] })
      }
    })
  }

  render() {
    const { areaList } = this.state
    let showList = areaList
    let columns: Array<ColumnsItem<StatisticInterface.AreaInfo & { deviceTotle: number }>> = [
      {
        key: `index`,
        name: `排名`,
        width: `50rem`,
        center: true,
        render: (text, record, index) => {
          let data
          switch (index) {
            case 0:
              data = <img className={styles.image} src={top1} />
              break
            case 1:
              data = <img className={styles.image} src={top2} />
              break
            case 2:
              data = <img className={styles.image} src={top3} />
              break
            default:
              data = <div className={styles.topText}>{`TOP${index + 1}`}</div>
              break
          }
          return data
        }
      },
      {
        key: `name`,
        name: `行政区域`,
        render: (text, record, index) => {
          return <div className={styles.text}>{text}</div>
        }
      },
      {
        key: `deviceTotal`,
        name: `联网设备`,
        width: `65rem`,
        center: true,
        render: (text, record, index) => {
          let total = 0
          if (
            record.deviceTypeStatistic &&
            record.deviceTypeStatistic.length > 0
          ) {
            record.deviceTypeStatistic.forEach(
              f1 => (total += Number(f1.total))
            )
          }
          return <div className={styles.text}>{total}</div>
        }
      },
      {
        key: `allAlarm`,
        name: `告警数量`,
        width: `65rem`,
        center: true,
        render: (text, record, index) => {
          let total = 0
          if (record.alarmStatistic && record.alarmStatistic.total) {
            total = Number(record.alarmStatistic.total)
          }
          return <div className={styles.text}>{total}</div>
        }
      },
      {
        key: `handlingAlarmRatio`,
        name: `处警率`,
        width: `55rem`,
        center: true,
        render: (text, record, index) => {
          let ratio = 1
          if (
            record.alarmStatistic &&
            record.alarmStatistic.handleTotal &&
            record.alarmStatistic.total
          ) {
            let total = Number(record.alarmStatistic.total)
            if (total > 0) {
              ratio = Number(record.alarmStatistic.handleTotal) / total
            }
          }
          return (
            <div className={styles.text}>
              {Math.round(Number(ratio) * 100)}%
            </div>
          )
        }
      }
      // {
      //     key: `apiRatio`,
      //     name: `接口稳定`,
      //     width: `65rem`,
      //     center: true,
      //     render: (text, record, index) => {
      //         return (
      //             <div className={styles.text}>{Math.round(Number(text) * 100)}%</div>
      //         )
      //     }
      // },
    ]

    return (
      <div className={styles.areaRankMain}>
        <Title icon={areaIcon}>行政区域排名</Title>
        <div className={styles.body}>
          <DataTable columns={columns} data={showList} />
        </div>
      </div>
    )
  }
}

export { AreaRank }
