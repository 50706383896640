import React, { PureComponent } from "react";
import ReactDOM from 'react-dom'
import styles from "./index.module.less"
import classnames from "classnames"
import AMapLoader from '@amap/amap-jsapi-loader';
import moment from "moment"
import { AmapKey } from "utils/constant";
import { InfoInterface } from "http-request/tokiot/info";
import { CommonParameter } from "utils/common-parameter";
import { AreaMarker } from "./area-marker";
import { MenuType } from "routes/home";
interface IProps {
    areaInfo: InfoInterface.AreaInfo,
}
class AmapMapTemp extends PureComponent<IProps>{
    state = {
    }


    //just some colors
    colors = [
        "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00",
        "#b82e2e", "#316395", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300", "#8b0707",
        "#651067", "#329262", "#5574a6", "#3b3eac"
    ];
    //当前聚焦的区域
    mapMain: any = null;
    districtExplorer: any = null;
    AMap: any = null;
    currentAreaNode: any = null;
    districtSearch: any = null;
    tipMarkerContent: any = document.createElement("div");
    tipMarker: any = null;
    district: any = null;
    polyEditor: any = null;
    componentDidMount() {
        AMapLoader.load({
            key: AmapKey,//首次load必填
            version: `1.4.15`, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            plugins: [`AMap.PolyEditor`],
            AMapUI: {
                version: '1.1',
                plugins: ['overlay/SimpleMarker', 'geo/DistrictExplorer'],
            }
        }).then((AMap: any) => {
            this.AMap = AMap;
            this.mapMain = new AMap.Map('container', {
                mapStyle: 'amap://styles/darkblue',
                resizeEnable: true, //是否监控地图容器尺寸变化
                zoom: 11, //初始化地图层级
                center: [116.397428, 39.90923] //初始化地图中心点
            });
            this.loadData();
        }).catch((e: any) => {
            console.log(e);
        })
    }
    componentDidUpdate(prevProps: IProps) {
        const { areaInfo } = this.props;
        if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
            this.loadData();
        }
    }


    loadData = () => {
        const { areaInfo } = this.props;
        const { AMap } = this;
        if (AMap) {
            if (!this.districtSearch) {
                AMap.plugin('AMap.DistrictSearch', () => {
                    // 创建行政区查询对象
                    this.districtSearch = new AMap.DistrictSearch({
                        // 关键字对应的行政区级别，country表示国家
                        level: 'country',
                        //  显示下级行政区级数，1表示返回下一级行政区
                        subdistrict: 1
                    })
                })
            }
            if (!this.tipMarker) {
                this.tipMarker = new AMap.Marker({
                    content: this.tipMarkerContent,
                    offset: new AMap.Pixel(30 * CommonParameter.FontSize, -(40 * CommonParameter.FontSize)),
                    bubble: true
                });
            }
            if (!this.districtExplorer) {
                //鼠标hover提示内容
                this.districtExplorer = new AMapUI.DistrictExplorer({
                    eventSupport: true, //打开事件支持
                    map: this.mapMain,
                });
                //监听feature的hover事件
                this.districtExplorer.on('featureMouseout featureMouseover', (e: any, feature: any) => {
                    this.toggleHoverFeature(feature.properties, e.type === 'featureMouseover',
                        e.originalEvent ? e.originalEvent.lnglat : null);
                });
            }

            this.switch2AreaNode(areaInfo);


            var path1 = [
                [118.651099, 24.721087],
                [118.651802, 24.723367],
            ];
            var path2 = [
                [118.653223, 24.728435],
                [118.651807, 24.723387],
                [118.646893, 24.724245],
            ];
            var path4 = [
                [118.651807, 24.723387],
                [118.657188, 24.722217]
            ];
            var path5 = [
                [118.645858, 24.728746],
                [118.64691, 24.724225],
                [118.645687, 24.721613],
            ];
            var path6 = [
                [118.645777, 24.721594],
                [118.651163, 24.720912],
                [118.652317, 24.720497],
                [118.653186, 24.720122],
                [118.654205, 24.719474],
                [118.656013, 24.718085],
            ];

            //全国
            let polyline1 = new AMap.Polyline({
                map: this.mapMain,
                path: path1,            // 设置线覆盖物路径
                showDir: true,
                strokeColor: '#cf1322',   // 线颜色
                strokeWeight: 10,          // 线宽
                lineJoin: `round`,
                lineCap: `round`,
            });
            new AMap.Polyline({
                map: this.mapMain,
                path: path2,            // 设置线覆盖物路径
                showDir: true,
                strokeColor: '#fa8c16',   // 线颜色
                strokeWeight: 10,          // 线宽
                lineJoin: `round`,
                lineCap: `round`,
            });
            new AMap.Polyline({
                map: this.mapMain,
                path: path6,            // 设置线覆盖物路径
                showDir: true,
                strokeColor: '#52c41a',   // 线颜色
                strokeWeight: 10,          // 线宽
                lineJoin: `round`,
                lineCap: `round`,
            });
            new AMap.Polyline({
                map: this.mapMain,
                path: path5,            // 设置线覆盖物路径
                showDir: true,
                strokeColor: '#fa8c16',   // 线颜色
                strokeWeight: 10,          // 线宽
                lineJoin: `round`,
                lineCap: `round`,
            });
          
         
            new AMap.Polyline({
                map: this.mapMain,
                path: path4,            // 设置线覆盖物路径
                showDir: true,
                strokeColor: '#52c41a',   // 线颜色
                strokeWeight: 10,          // 线宽
                lineJoin: `round`,
                lineCap: `round`,
            });
           
            // this.polyEditor = new AMap.PolyEditor(this.mapMain, polyline1)

            // this.polyEditor.on('addnode', (event: any) => {
            //     console.info('触发事件：addnode', event)
            // })

            // this.polyEditor.on('adjust', (event: any) => {
            //     console.info('触发事件：adjust', event.lnglat.lng, event.lnglat.lat)
            // })

            // this.polyEditor.on('removenode', (event: any) => {
            //     console.info('触发事件：removenode', event)
            // })

            // this.polyEditor.on('end', (event: any) => {
            //     console.info('触发事件： end', event)
            //     // event.target 即为编辑后的折线对象
            // })
            // this.polyEditor.open();
        }
    }

    //绘制某个区域的边界
    renderAreaPolygons = (areaNode: any) => {
        const { colors, districtExplorer } = this;
        //更新地图视野
        this.mapMain.setBounds(areaNode.getBounds(), null, null, true);
        //设置定位节点，支持鼠标位置识别
        districtExplorer.setAreaNodesForLocating(areaNode);
        //清除已有的绘制内容
        districtExplorer.clearFeaturePolygons();
        //绘制子区域
        districtExplorer.renderSubFeatures(areaNode, (feature: any, i: number) => {
            let fillColor = colors[i % colors.length];
            let strokeColor = colors[colors.length - 1 - i % colors.length];
            return {
                cursor: 'default',
                bubble: true,
                strokeColor: strokeColor, //线颜色
                strokeOpacity: 1, //线透明度
                strokeWeight: 1, //线宽
                fillColor: fillColor, //填充色
                fillOpacity: 0.35, //填充透明度
            };
        });

        //绘制父区域
        districtExplorer.renderParentFeature(areaNode, {
            cursor: 'default',
            bubble: true,
            strokeColor: '#666', //线颜色
            strokeOpacity: 1, //线透明度
            strokeWeight: 1, //线宽
            fillColor: areaNode.getSubFeatures().length ? null : colors[0], //填充色
            fillOpacity: 0.35, //填充透明度
        });
    }

    //切换区域后刷新显示内容
    refreshAreaNode = (areaNode: any) => {
        const { currentAreaNode, districtExplorer } = this;
        districtExplorer.setHoverFeature(null);
        this.renderAreaPolygons(areaNode);
    }

    //切换区域
    switch2AreaNode = (areaInfo: InfoInterface.AreaInfo) => {
        const { districtSearch, currentAreaNode, districtExplorer } = this;
        this.mapMain.clearMap();
        if (districtSearch) {
            districtSearch.setLevel(areaInfo.level); //行政区级别
            // 行政区查询
            // 按照adcode进行查询可以保证数据返回的唯一性
            districtSearch.search(areaInfo.name, (status: any, result: any) => {
                if (status === 'complete' &&
                    result.districtList && result.districtList.length > 0 &&
                    result.districtList[0].districtList && result.districtList[0].districtList.length > 0
                ) {
                    result.districtList[0].districtList.forEach((element: any) => {
                        if (areaInfo.children && areaInfo.children.length > 0) {
                            let info = areaInfo.children.find(f1 => f1.name === element.name);
                            if (info) {
                                element.parentAreaInfo = areaInfo;
                                element.code = info.code;
                                element.sort = element.adcode;
                                this.AddMarker(element);
                            }
                        }
                    });
                }
            });
        }
        this.loadAreaNode(areaInfo.sort, (error: any, areaNode?: any) => {
            if (error) {
                return;
            }
            this.currentAreaNode = areaNode;
            //设置当前使用的定位用节点
            districtExplorer.setAreaNodesForLocating([currentAreaNode]);
            this.refreshAreaNode(areaNode);
            this.mapMain.setZoom(16);
            this.mapMain.setCenter([118.655004, 24.722695]);
        });

    }

    //加载区域
    loadAreaNode = (adcode: string, callback: (error: any, areaNode?: any) => void) => {
        const { currentAreaNode, districtExplorer } = this;
        districtExplorer.loadAreaNode(adcode, (error: any, areaNode: any) => {
            if (error) {
                callback && callback(error);
                console.error(error);
                return;
            }
            callback && callback(null, areaNode);
        });
    }


    //根据Hover状态设置相关样式
    toggleHoverFeature = (areaInfo: InfoInterface.AreaInfo, isHover: boolean, position: any) => {
        if (!areaInfo) return;
        //更新相关多边形的样式
        let polys = this.districtExplorer.findFeaturePolygonsByAdcode(areaInfo.sort);
        for (let i = 0, len = polys.length; i < len; i++) {
            polys[i].setOptions({
                fillOpacity: isHover ? 0.5 : 0.2
            });
        }
    }

    isMarkerHover = false;
    //根据Hover状态设置相关样式
    markerHover = (areaInfo: InfoInterface.AreaInfo, isHover: boolean, position: any) => {
        if (this.isMarkerHover !== isHover) {
            this.isMarkerHover = isHover;
            //更新位置
            let upData = (isHover: boolean) => {
                if (isHover) {
                    this.tipMarker.setPosition(position);
                }
                this.tipMarker.setMap(isHover ? this.mapMain : undefined);
                this.toggleHoverFeature(areaInfo, isHover, position);
            }
            if (areaInfo && isHover) {
                //悬浮窗口
                this.getMarkerInfo(areaInfo, upData);
            } else {
                upData(false);
            }
        }
    }

    getMarkerInfo = (areaInfo: InfoInterface.AreaInfo, callback: (isHover: boolean) => void) => {
        if (areaInfo && areaInfo.parentAreaInfo) {
            ReactDOM.render(<AreaMarker areaInfo={areaInfo} />, this.tipMarkerContent);
            callback && callback(true);
        }
    }

    //marker单击事件
    markerClick = (setAreaInfo: any) => {
        const { areaInfo } = this.props;
        if (setAreaInfo.level === "street") {
            if (areaInfo.parentAreaInfo) {
                if (areaInfo.parentAreaInfo.level === `district`) {
                    setAreaInfo.parentAreaInfo = areaInfo.parentAreaInfo;
                } else if (areaInfo.parentAreaInfo.level === `city`) {
                    setAreaInfo.parentAreaInfo = areaInfo;
                }
            }
        } else {
            setAreaInfo.parentAreaInfo = areaInfo;
        }
        CommonParameter.SetSelectArea && CommonParameter.SetSelectArea(setAreaInfo);
    };

    //添加行政区域的中心点
    AddMarker = (areaInfo: any) => {
        let markerElement = document.createElement("div");
        let marker = new this.AMap.Marker({
            map: this.mapMain,
            position: areaInfo.center,
            content: markerElement,
            offset: new this.AMap.Pixel(-(25 * CommonParameter.FontSize), -(50 * CommonParameter.FontSize)),
            bubble: false,
        });
        marker.on('mouseout', () => {
            this.markerHover(areaInfo, false, null);
        });
        marker.on('mouseover', () => {
            this.markerHover(areaInfo, true, areaInfo.center);
        });
        marker.on('click', () => {
            this.markerClick(areaInfo);
        });
        ReactDOM.render(
            <div className={styles.marker}></div>,
            markerElement);
    }

    render() {
        return (
            <div id={`container`} className={styles.mapMain} >
                <div className={styles.color1}></div>
                <div className={styles.color2}></div>
            </div>
        );
    }
}

export { AmapMapTemp };

