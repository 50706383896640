import React from "react";
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { Login } from "./login";


class AccountRoutesFc extends React.PureComponent<RouteComponentProps>{
    render() {
        return (
            <Switch>
                <Route exact path="/account/login" component={Login} />
                <Redirect from='/' to={`/account/login`} />
            </Switch>
        );
    }
}
let AccountRoutes = withRouter(AccountRoutesFc);
export { AccountRoutes };