import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
interface IProps {
  className?: string,
  icon: string,
}
class Title extends PureComponent<IProps> {
  state = {}
  componentDidMount() { }
  render() {
    const { className, children, icon } = this.props
    return (
      <div className={classnames(className, styles.title)}>
        <img className={styles.image} src={icon} alt="" />
        <span className={styles.text}>{children}</span>
      </div>
    )
  }
}

export { Title }
