import { ParamsId } from 'http-request/interface';
import { InfoInterface } from 'http-request/tokiot/info';
export interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
}
type Refresh<T> = (param?: T) => void;
class RefreshClass<T = {}> {
    private chageList: Array<Refresh<T>> = [];
    public AddFun(fun: Refresh<T>) {
        if (this.chageList.findIndex(f1 => f1 === fun) < 0) {
            this.chageList.push(fun);
        }
    }
    public Refresh(param?: T) {
        if (this.chageList.length > 0) {
            this.chageList.forEach(f1 => f1(param));
        }
    }
}
class CommonParameterModal {
    /** 单例 */
    private static readonly self = new CommonParameterModal();
    public static GetCommonParameterModal = (): CommonParameterModal => {
        return CommonParameterModal.self;
    }

    /** body fontsize */
    private fontSize: number = 0;
    public set FontSize(value: number) {
        this.fontSize = value;
    }
    public get FontSize() {
        return this.fontSize;
    }

    /** 比较ID是否改变 */
    public isChange = (data1?: ParamsId, data2?: ParamsId) => {
        let id1 = undefined;
        let id2 = undefined;
        if (data1 && data1.id) {
            id1 = data1.id;
        }
        if (data2 && data2.id) {
            id2 = data2.id;
        }
        return id1 !== id2;
    };

    /** 选中区域 */
    public SetSelectArea?: (selectArea: InfoInterface.AreaInfo) => void;


    public BaseData = [{ name: `市机关宿舍楼`, unityName: `湖边`, type: `市政府办`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `蚶江镇宿舍楼`, unityName: `湖边`, type: `蚶江镇政府`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `祥芝镇宿舍楼`, unityName: `湖边`, type: `祥芝镇政府`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `农资花苑`, unityName: `湖边`, type: `市农办`, unit: ``, isMeet: `false`, remark: `` },
    { name: `公安局宿舍`, unityName: `湖边`, type: `公安局`, unit: `石狮市保安公司`, isMeet: `true`, remark: `老旧小区` },
    { name: `华侨新村`, unityName: `湖边`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `玉兰小区`, unityName: `湖边`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `石狮市中心农贸市场`, unityName: `新湖`, type: `市市场监督管理局`, unit: ``, isMeet: `true`, remark: `老旧小区` },
    { name: `供销社宿舍楼`, unityName: `新湖`, type: `供销合作社联合社`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `影剧院宿舍楼`, unityName: `新湖`, type: `市文体旅游广电新闻出版局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `华友宿舍楼`, unityName: `新湖`, type: `市商务局`, unit: ``, isMeet: `false`, remark: `` },
    { name: `恒辉商厦`, unityName: `新湖`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `天桥巷商住房`, unityName: `新湖`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `工艺商品楼`, unityName: `新湖`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `洋顶路商品楼`, unityName: `新湖`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `花园大厦`, unityName: `新湖`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `狮城商品楼`, unityName: `新湖`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `恒安商品楼`, unityName: `新湖`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `步行街`, unityName: `新湖`, type: ``, unit: ``, isMeet: `true`, remark: `` },
    { name: `狮城帝苑`, unityName: `玉湖`, type: ``, unit: `景鸿物业`, isMeet: `true`, remark: `` },
    { name: `玉湖果蔬大厦`, unityName: `玉湖`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `名仕豪园`, unityName: `玉湖`, type: ``, unit: ``, isMeet: `true`, remark: `` },
    { name: `中信商住楼`, unityName: ``, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `向荣楼`, unityName: ``, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `玉兰商住楼`, unityName: ``, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `汇景豪庭`, unityName: `玉湖`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `和冠商厦`, unityName: `金林`, type: ``, unit: `佳益物业`, isMeet: `false`, remark: `` },
    { name: `烟糖酒宿舍楼`, unityName: `金林`, type: `市商务局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `市外经委宿舍楼`, unityName: `金林`, type: `市商务局`, unit: ``, isMeet: `true`, remark: `老旧小区` },
    { name: `国土综合楼`, unityName: `金林`, type: `市交通和城市建设局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `富禄苑`, unityName: `金林`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `金光巷20号`, unityName: `金林`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `金光巷63号`, unityName: `金林`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `颖川巷商住楼`, unityName: `金林`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `福兴路73号小区`, unityName: `金林`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `泉美豪庭`, unityName: `金林`, type: ``, unit: ``, isMeet: `true`, remark: `` },
    { name: `嘉丰华庭`, unityName: `金曾`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `芳星豪园`, unityName: `金曾`, type: ``, unit: ``, isMeet: `true`, remark: `` },
    { name: `公安宿舍`, unityName: `金曾`, type: `公安局`, unit: ``, isMeet: `true`, remark: `老旧小区` },
    { name: `永宁公寓`, unityName: `金曾`, type: `永宁镇政府`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `金定鸭场`, unityName: `金曾`, type: `金定鸭坊`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `武装部宿舍`, unityName: `金曾`, type: `武装部`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `宝盖镇宿舍`, unityName: `金曾`, type: `宝盖镇`, unit: ``, isMeet: `false`, remark: `` },
    { name: `渔监大厦`, unityName: `金曾`, type: `渔监局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `建德花园二期`, unityName: `曾坑`, type: ``, unit: `德泰物业`, isMeet: `true`, remark: `` },
    { name: `建德商住楼`, unityName: `曾坑`, type: ``, unit: `佳益物业`, isMeet: `true`, remark: `老旧小区` },
    { name: `德辉商业街`, unityName: `曾坑`, type: ``, unit: `德辉物业`, isMeet: `false`, remark: `老旧小区` },
    { name: `建德一期`, unityName: `曾坑`, type: ``, unit: `德泰物业`, isMeet: `false`, remark: `老旧小区` },
    { name: `畔山云海`, unityName: `曾坑`, type: ``, unit: `德泰物业`, isMeet: `false`, remark: `` },
    { name: `菲律宾广场`, unityName: `曾坑`, type: ``, unit: `德泰物业`, isMeet: `false`, remark: `` },
    { name: `濠江丽景`, unityName: `曾坑`, type: ``, unit: `德泰物业`, isMeet: `true`, remark: `` },
    { name: `国税花苑`, unityName: `曾坑`, type: `市国税局`, unit: `鸿祥物业`, isMeet: `true`, remark: `` },
    { name: `热电佳园`, unityName: `曾坑`, type: `鸿山热电厂`, unit: ``, isMeet: `true`, remark: `` },
    { name: `石油公司宿舍`, unityName: `曾坑`, type: `石油公司`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `建行宿舍`, unityName: `曾坑`, type: `建设银行`, unit: ``, isMeet: `false`, remark: `` },
    { name: `征达商住楼`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `豪富广场`, unityName: `曾坑`, type: ``, unit: `宁居物业`, isMeet: `false`, remark: `` },
    { name: `东港花苑`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `true`, remark: `` },
    { name: `世纪金源`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `南芳华庭`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `星豪花园`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `阳峰商厦`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `濠江时代广场`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `true`, remark: `` },
    { name: `澳门同乡会`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `征达小区`, unityName: `曾坑`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `宝光豪庭`, unityName: `花园城`, type: ``, unit: `宏运物业`, isMeet: `true`, remark: `` },
    { name: `地税嘉源小区`, unityName: `花园城`, type: `市地税局`, unit: `鸿祥物业`, isMeet: `true`, remark: `` },
    { name: `海关宿舍楼`, unityName: `花园城`, type: `泉州海关驻石狮办事处`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `计生委宿舍`, unityName: `花园城`, type: `市卫生和计划生育局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `市直粮库宿舍`, unityName: `花园城`, type: `市直粮库`, unit: ``, isMeet: `false`, remark: `拆迁` },
    { name: `东华小区`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `防疫站小区`, unityName: `花园城`, type: `防疫站`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `公路局小区`, unityName: `花园城`, type: `公路局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `华宝花园`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `嘉华小区`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `嘉禄花苑`, unityName: `花园城`, type: ``, unit: ``, isMeet: `true`, remark: `老旧小区` },
    { name: `富丽宝`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `华兴小区`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `花园城小区`, unityName: `花园城`, type: ``, unit: `鸿祥物业`, isMeet: `true`, remark: `老旧小区` },
    { name: `海景大厦`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `莲华花苑`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `竹林轩`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `仙鹤楼`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `拆迁` },
    { name: `天宝大厦`, unityName: `花园城`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `新华友商厦`, unityName: `仙迹`, type: ``, unit: `佳益物业`, isMeet: `true`, remark: `老旧小区` },
    { name: `中旅宿舍楼`, unityName: `仙迹`, type: `中旅社`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `仙迹社区综合楼`, unityName: `仙迹`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `侨联商厦`, unityName: `仙迹`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `五交化宿舍楼`, unityName: `仙迹`, type: `五交化`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `侨馨大厦`, unityName: `仙迹`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `新华书店宿舍`, unityName: `仙迹`, type: `新华书店`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `石狮宾馆宿舍`, unityName: `仙迹`, type: `石狮宾馆`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `豪富花园`, unityName: `林边`, type: ``, unit: `佳益物业`, isMeet: `true`, remark: `` },
    { name: `狮城假日公寓`, unityName: `林边`, type: ``, unit: `佳益物业`, isMeet: `true`, remark: `` },
    { name: `中天国际`, unityName: `林边`, type: ``, unit: `佳益物业`, isMeet: `true`, remark: `` },
    { name: `嘉利苑`, unityName: `林边`, type: ``, unit: `福州西酒物业管理有限公司`, isMeet: `false`, remark: `` },
    { name: `烟草宿舍楼`, unityName: `林边`, type: `烟草公司`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `地税宿舍楼`, unityName: `林边`, type: `市地税局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `国税宿舍楼`, unityName: `林边`, type: `市国税局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `工商宿舍楼`, unityName: `林边`, type: `市市场监督管理局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `教育局宿舍楼`, unityName: `林边`, type: `市教育局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `法院宿舍楼`, unityName: `林边`, type: `市法院`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `内务局宿舍楼`, unityName: `林边`, type: `市委社会工作部`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `电视台宿舍楼`, unityName: `林边`, type: `广播电视台`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `房地产宿舍楼`, unityName: `林边`, type: `市国土规划和房产管理局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `安全局宿舍楼`, unityName: `林边`, type: `市司法局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `城镇集体工业联合社宿舍楼`, unityName: `林边`, type: `城镇集体工业联合社`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `就业管理处宿舍楼`, unityName: `林边`, type: `市人力资源和社会保障局`, unit: ``, isMeet: `false`, remark: `` },
    { name: `经济局宿舍楼`, unityName: `林边`, type: `市经济局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `检察院宿舍楼`, unityName: `林边`, type: `市检察院`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `边防宿舍楼`, unityName: `林边`, type: `边防局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `祥鹏大厦`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `世纪家园`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `绿馨一期`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `绿馨二期`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `豪景苑`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `华禄`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `华丰`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `桥兴大厦`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `林边社区综合楼`, unityName: `林边`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `泰禾首玺`, unityName: `长福`, type: ``, unit: `福州泰禾物业管理有限公司石狮分公司`, isMeet: `false`, remark: `` },
    { name: `中骏天誉`, unityName: `长福`, type: ``, unit: `福建世邦泰和物业管理有限公司`, isMeet: `true`, remark: `` },
    { name: `濠江大厦`, unityName: `长福`, type: ``, unit: `福建省德泰物业管理有限公司`, isMeet: `false`, remark: `` },
    { name: `丽景花苑`, unityName: `长福`, type: ``, unit: `佳益物业`, isMeet: `true`, remark: `` },
    { name: `嘉福华庭`, unityName: `长福`, type: ``, unit: `仁德物业服务有限公司`, isMeet: `true`, remark: `` },
    { name: `世府名门`, unityName: `长福`, type: ``, unit: `仁德物业服务有限公司`, isMeet: `true`, remark: `` },
    { name: `富贵天骄`, unityName: `长福`, type: ``, unit: `厦门仁景物业`, isMeet: `true`, remark: `` },
    { name: `香格丽舍`, unityName: `长福`, type: `　`, unit: `佳益物业`, isMeet: `true`, remark: `` },
    { name: `国土规划宿舍楼`, unityName: `长福`, type: `市国土规划和房产 管理局`, unit: ``, isMeet: `true`, remark: `老旧小区` },
    { name: `审计宿舍楼`, unityName: `长福`, type: `市审计局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `税务老干宿舍楼`, unityName: `长福`, type: `市国税局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `津毓花苑`, unityName: `长福`, type: `市教育局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `交通局宿舍楼`, unityName: `长福`, type: `交通局`, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `渔政宿舍楼`, unityName: `长福`, type: `渔政`, unit: ``, isMeet: `true`, remark: `老旧小区` },
    { name: `邮电新村`, unityName: `长福`, type: ``, unit: ``, isMeet: `false`, remark: `老旧小区` },
    { name: `宝岛佳园`, unityName: `长福`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `辉凤大厦`, unityName: `长福`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `瀚昌豪园`, unityName: `长福`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `丽景花苑一期`, unityName: `长福`, type: ``, unit: ``, isMeet: `true`, remark: `老旧小区` },
    { name: `怡和大厦`, unityName: `长福`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `龙福小区`, unityName: `长福`, type: ``, unit: `彩生活物业`, isMeet: `false`, remark: `` },
    { name: `紫荆大厦`, unityName: `长福`, type: ``, unit: ``, isMeet: `false`, remark: `` },
    { name: `状元府邸`, unityName: `长福`, type: ``, unit: `状元物业管理`, isMeet: `true`, remark: `` },
    { name: `富兴源`, unityName: `长福`, type: ``, unit: ``, isMeet: `true`, remark: `` }]
}

let CommonParameter = CommonParameterModal.GetCommonParameterModal();
export { CommonParameter };


