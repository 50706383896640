import React, { PureComponent } from 'react'
import styles from './index.module.less'
import $$ from 'cmn-utils'
import VideoTop from './Top'
import VideoLeft from './Left'
import VideoRight from './Right'
import { AreaContext, UserContext } from "context/context-react";

class Video extends PureComponent {
  constructor(props) {
    super(props)
    let key = this.getCacheKey()
    const monitorState = $$.getStore(key)
    this.state = monitorState || {
      gridCount: 1,
      gridKeys: [
        'grid1',
        'grid2',
        'grid3',
        'grid4',
        'grid5',
        'grid6',
        'grid7',
        'grid8'
      ],
      gridMap: {} // 存放设备信息
    }
  }

  componentDidMount() { }

  saveState = () => {
    let key = this.getCacheKey()
    $$.setStore(key, this.state)
  }

  getCacheKey = () => {
    let cacheKey = 'monitorState'
    // 如果能取到用户名，则拼接用户名作为缓存的key
    let headers = $$.getStore('headers')
    if (headers && headers.Username && headers.Username.length > 0) {
      cacheKey = cacheKey + '-' + headers.Username
    }
    return cacheKey
  }

  onSetGridCount = gridCount => {
    this.setState({ gridCount }, this.saveState)
  }

  onSelect = (device, gridKey) => {
    const { gridMap } = this.state
    gridMap[gridKey] = device
    this.setState({ gridMap: { ...gridMap } }, this.saveState)
  }

  onCloseWindow = gridKey => {
    const { gridMap } = this.state
    gridMap[gridKey] = ''
    this.setState({ gridMap: { ...gridMap } }, this.saveState)
  }

  render() {
    let { gridCount, gridKeys, gridMap } = this.state
    gridKeys = gridKeys.filter((i, index) => index < gridCount)
    return (
      <div className={styles.main}>
        <VideoTop onSetGridCount={this.onSetGridCount} />
        <div className={styles.body}>
          <UserContext.Context.Consumer>
            {user => <AreaContext.Context.Consumer>
              {area => {
                let param = {
                  userInfo: user,
                  areaInfo: area,
                }
                return (<React.Fragment>
                  <VideoLeft {...param} gridKeys={gridKeys} onSelect={this.onSelect} />
                </React.Fragment>)
              }}
            </AreaContext.Context.Consumer>}
          </UserContext.Context.Consumer>
          <VideoRight
            gridMap={gridMap}
            gridKeys={gridKeys}
            gridCount={gridCount}
            onCloseWindow={this.onCloseWindow}
          />
        </div>
      </div>
    )
  }
}

export default Video
