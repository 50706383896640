import React from "react";
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import Video from "./components";

class VideoRoutesFc extends React.PureComponent<RouteComponentProps>{
    render() {
        return (
            <Switch>
                <Route exact path="/home/video" component={Video} />
                <Redirect from='/' to={`/home/video`} />
            </Switch>
        );
    }
}
let VideoRoutes = withRouter(VideoRoutesFc);
export { VideoRoutes };