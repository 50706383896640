export const ClientVersion = 'V2.0.0.20220113.cluster' // 程序版本

export const BaseUrl = '/iot-city-api' // 生产
export const FlvApiUrl = '/flv-api/' // 播放器地址

//上传图片
export const UploadImg = BaseUrl + '/api/other/upload'
export const GetWSUrl = (url: String) => `${'https:' === window.location.protocol ? 'wss' : 'ws'}://${window.location.host}${BaseUrl}/websocket/root`;
//查看图片
export const DownloadImg = BaseUrl + '/api/other/download-image-stream?fileId='
//语音报警文件
export const VoiceUrl = BaseUrl + '/api/other/download-audio-stream?fileId='

//高德地图JSkey
export const AmapKey = '4657af810f68c85bf65a23cb17d014ac'
