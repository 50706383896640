import React, { PureComponent } from "react";
import styles from "./index.module.less"
import moment from "moment"

class Time extends PureComponent {
    state = {
        dateTime: moment(),
    }

    componentDidMount() {
        const timer = setInterval(() => {
            this.setState({ dateTime: moment() })
        }, 1 * 1000)
        this.setState({ timer })
    }

    componentWillUnmount() {
        const { timer } = this.state
        clearInterval(timer)
    }

    render() {
        const { dateTime } = this.state

        return (
            <div className={styles.timeMain} >
                <div className={styles.day} >
                    <div className={styles.text} >
                        {dateTime.format('YYYY年MM月DD日')}
                    </div>
                    <div className={styles.text} >
                        {dateTime.format('dddd')}
                    </div>
                </div>
                <div className={styles.time} >
                    {dateTime.format('HH:mm:ss')}
                </div>
            </div>
        );
    }


}

export default Time;

