import classNames from 'classnames';
import React from 'react';
import styles from './common-box.module.less';

class CommonBox extends React.PureComponent<{ className?: string }> {
    render() {
        const { className } = this.props
        return (
            <div className={classNames(className, styles.boxMain)}>
                <div className={styles.topSole}></div>
                <div className={styles.body}>
                    {this.props.children}
                </div>
                <div className={styles.bottomSole}></div>
            </div>
        );
    }
}

export { CommonBox };