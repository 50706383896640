import { CommonParameter } from "utils/common-parameter"
const SetHtmlFontSize = () => {
    const html = document.getElementsByTagName('html')[0];
    const { width: windowsWidth, height: windowsHeight } = window.screen;
    const GetFontSize = (data: number, factor: number) => {
        let ret = data / factor;
        if (ret > 1) {
            ret = Math.ceil(ret);
        }
        return ret;
    }
    let showFontSize = GetFontSize(windowsWidth, 1920);
    if (windowsWidth === 9600) {
        showFontSize = 3;
    }
    html.style.fontSize = showFontSize + 'px';
    CommonParameter.FontSize = showFontSize;
}

export { SetHtmlFontSize };