import React, { PureComponent } from 'react'
import styles from './index.module.less'
import { Popover, Input } from 'antd'
import { Info } from 'http-request/tokiot/info'
import { CommonParameter } from "utils/common-parameter";
const { Search } = Input

// 设备item
class DeviceItem extends PureComponent {
  state = {
    popVisible: false
  }

  onItemClick = () => {
    const { gridKeys } = this.props
    if (gridKeys.length == 1) {
      this.onSelect(gridKeys[0])
    }
  }

  onVisibleChange = popVisible => this.setState({ popVisible })
  getPopoverContent = gridKeys => {
    return (
      <ul style={{ lineHeight: 1.8 }}>
        {gridKeys.map((gridKey, index) => (
          <a onClick={() => this.onSelect(gridKey)}>
            <li key={gridKey}>{index + 1}号窗口</li>
          </a>
        ))}
      </ul>
    )
  }
  onSelect = gridKey => {
    const { device, onSelect } = this.props
    onSelect(device, gridKey)
    this.setState({ popVisible: false })
  }

  render() {
    const { device, gridKeys } = this.props
    const { popVisible } = this.state
    let deviceStatus
    let statusStyle
    switch (device.state) {
      case 'Normal':
        deviceStatus = '正常'
        statusStyle = styles.deviceStatusNormal
        break
      case 'Lose':
        deviceStatus = '失联'
        statusStyle = styles.deviceStatusLose
        break
      default:
        deviceStatus = '离线'
        statusStyle = styles.deviceStatusOffline
        break
    }
    return (
      <Popover
        visible={popVisible}
        placement='rightTop'
        trigger='click'
        title='选择播放窗口'
        content={this.getPopoverContent(gridKeys)}
        onVisibleChange={gridKeys.length != 1 && this.onVisibleChange}
      >
        <div className={styles.deviceItem} onClick={e => this.onItemClick()}>
          <span className={statusStyle}>{deviceStatus}</span>
          <span className={styles.deviceTitle}>{device.name}</span>
        </div>
      </Popover>
    )
  }
}

class VideoLeft extends PureComponent {
  state = {
    deviceList: [],
    searchText: ''
  }

  componentDidMount() {
    this.getDeviceList()
  }

  componentDidUpdate(prevProps) {
    const { areaInfo } = this.props;
    if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
      this.getDeviceList()
    }
  }

  getDeviceList = () => {
    const { areaInfo } = this.props
    if (!areaInfo) return
    let data = { typeCode: `SPJK` }
    data.areaCode = areaInfo.code;
    Info.DeviceList(data).then(ret => {
      if (ret && ret.length > 0) {
        this.setState({ deviceList: ret })
      } else {
        this.setState({ deviceList: [] })
      }
    })
  }

  onSearch = searchText => {
    this.setState({ searchText })
  }

  filterDevice = () => {
    const { deviceList, searchText } = this.state
    return deviceList.filter(
      item =>
        item.name.indexOf(searchText) != -1 ||
        item.address.indexOf(searchText) != -1 ||
        item.sn.indexOf(searchText) != -1
    )
  }

  render() {
    const { gridKeys, onSelect } = this.props
    return (
      <div className={styles.main}>
        <Search onSearch={this.onSearch} placeholder='关键词搜索' />
        <div className={styles.deviceList}>
          {this.filterDevice().map((f1, index) => (
            <DeviceItem device={f1} gridKeys={gridKeys} onSelect={onSelect} />
          ))}
        </div>
      </div>
    )
  }
}

export default VideoLeft
