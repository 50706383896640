import React, { PureComponent } from "react";
import styles from "./index.module.less"
import classnames from "classnames"
import { StatisticCommon, StatisticCommonInterface } from "http-request/tokiot/statistic/common";
import { InfoInterface } from "http-request/tokiot/info";
import { CommonParameter } from "utils/common-parameter";
interface IProps {
    areaInfo: InfoInterface.AreaInfo,
}
interface IState {
    deviceTypeList: Array<StatisticCommonInterface.DeviceTypeInfo>,
}
class DeviceStateTotal extends PureComponent<IProps, IState>{
    state: IState = {
        deviceTypeList: [],
    }

    componentDidMount() {
        this.getDeviceType();
    }

    componentDidUpdate(prevProps: IProps) {
        const { areaInfo } = this.props;
        if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
            this.getDeviceType();
        }
    }


    getDeviceType = () => {
        const { areaInfo } = this.props;
        StatisticCommon.DeviceType({ areaCode: areaInfo.code }).then(ret => {
            if (ret && ret.length > 0) {
                ret.sort((f1, f2) => Number(f2.total) - Number(f1.total));
                this.setState({ deviceTypeList: ret });
            } else {
                this.setState({ deviceTypeList: [] });
            }
        });
    }

    getItem = (item: { name: string, total: number, }, index: number) => {
        return (
            <div className={classnames(styles.item, {
                [styles.red]: index === 1,
                [styles.gray]: index === 2,
            })}>
                <div className={styles.itemBg}>
                </div>
                <div className={styles.info}>
                    <div className={styles.total}>{Number(item.total).toLocaleString()}</div>
                    <div className={styles.name}>{item.name}</div>
                </div>
                <div className={styles.line}></div>
            </div>
        )
    }

    render() {
        const { deviceTypeList } = this.state;
        let data = [
            {
                name: `正常设备数`,
                total: 0,
            },
            {
                name: `告警设备数`,
                total: 0,
            },
            {
                name: `离线设备数`,
                total: 0,
            },
        ]
        if (deviceTypeList && deviceTypeList.length > 0) {
            deviceTypeList.forEach(f1 => {
                data[0].total += Number(f1.normalTotal);
                data[1].total += Number(f1.alarmTotal);
                data[2].total += (Number(f1.offlineTotal) + Number(f1.loseTotal) + Number(f1.notActiveTotal));
            })
        }

        return (
            <div className={styles.deviceStateTotalMain} >
                {data.map((f1, index) => this.getItem(f1, index))}
            </div>
        );
    }
}

export { DeviceStateTotal };

