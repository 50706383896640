import { AreaContext, UserContext } from "context/context-react";
import { Info, InfoInterface } from "http-request/tokiot/info";
import React from "react";
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { VideoRoutes } from "routes/video";
import { CommonParameter } from "utils/common-parameter";
import { Home } from "./components";
export type MenuType =
    `index` |
    `lakeside`
    ;
interface IState {
    userInfo?: InfoInterface.UserInfo;
    areaInfo?: InfoInterface.AreaInfo;
}
class HomeRoutesFc extends React.PureComponent<RouteComponentProps<{ id: string }>, IState>{
    state: IState = {

    }
    componentDidMount() {
        this.getUserInfo();
        CommonParameter.SetSelectArea = this.getAreaInfo;
    }


    getUserInfo = () => {
        Info.User().then(ret => {
            let areaInfo: InfoInterface.AreaInfo;
            if (ret) {
                this.setState({ userInfo: ret });
            }
            if (ret && ret.areaList && ret.areaList.length > 0) {
                areaInfo = {
                    ...ret.areaList[0],
                    parentAreaInfo: undefined,
                };
                areaInfo.sort = areaInfo.code.toString().substring(0, 6);
            } else {
                areaInfo = {
                    code: `350500000000`,
                    level: `city`,
                    name: `泉州市`,
                    parentAreaInfo: undefined,
                    id: `350500000000`,
                    sort: `350500`,
                }
            }
            this.getAreaInfo(areaInfo);
        })

    }
    getAreaInfo = (areaInfo: InfoInterface.AreaInfo) => {
        Info.AreaSyncLoad({ parentCode: areaInfo.code }).then(ret => {
            if (ret && ret.length > 0) {
                let newAreaInfo: InfoInterface.AreaInfo = { ...areaInfo };
                newAreaInfo.children = ret;
                this.setState({ areaInfo: newAreaInfo });
            }
        })
    }

    render() {
        const { userInfo, areaInfo } = this.state;
        if (!(userInfo && areaInfo)) return ``;
        return (
            <UserContext.Context.Provider value={userInfo}>
                <AreaContext.Context.Provider value={areaInfo}>
                    <Switch>
                        <Route path="/home/video" render={() => <VideoRoutes />} />
                        <Route exact path="/home/:features" component={Home} />
                        <Redirect from='/' to={`/home/index`} />
                    </Switch>
                </AreaContext.Context.Provider>
            </UserContext.Context.Provider>
        );
    }
}
let HomeRoutes = withRouter(HomeRoutesFc);
export { HomeRoutes };