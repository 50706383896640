import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import top1 from './image/1.png'
import top2 from './image/2.png'
import top3 from './image/3.png'
import areaIcon from './image/area.png'
import { InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
import { Statistic, StatisticInterface } from 'http-request/tokiot/statistic'
import { Title } from '../../title'
import { ColumnsItem, DataTable } from '../../data-table'
import { CommunityInfo } from './info'
type DataType = typeof CommonParameter.BaseData[0];
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
  visible: boolean,
  selectData?: DataType;
}
class CommunityRank extends PureComponent<IProps, IState> {
  state: IState = {
    visible: false,
  }

  componentDidMount() {
  }
  componentDidUpdate(prevProps: IProps) {
  }


  selectData = (selectData?: DataType) => {
    this.setState({ visible: !this.state.visible, selectData });
  }

  render() {
    const { visible, selectData } = this.state;
    const { BaseData } = CommonParameter;
    let columns: Array<ColumnsItem<DataType>> = [
      {
        key: `index`,
        name: `序号`,
        width: `50rem`,
        center: true,
        render: (text, record, index) => {
          return <div className={styles.topText} onClick={e => this.selectData(record)}>{`${index + 1}`}</div>
        }
      },
      {
        key: `name`,
        name: `小区名称`,
        render: (text, record, index) => {
          return <div className={styles.text} onClick={e => this.selectData(record)}>{text}</div>
        }
      },
      {
        key: `unityName`,
        name: `所属社区`,
        width: `55rem`,
        center: true,
        render: (text, record, index) => {
          return <div className={styles.text} onClick={e => this.selectData(record)}>{text}</div>
        }
      }

    ]

    return (
      <div className={styles.areaRankMain}>
        {visible && selectData ? <CommunityInfo visible={visible} selectCommunity={selectData} onClose={() => { this.selectData() }} /> : ``}
        <Title icon={areaIcon}>小区列表</Title>
        <div className={styles.body}>
          <DataTable columns={columns} data={BaseData} />
        </div>
      </div>
    )
  }
}

export { CommunityRank }
