import React from "react";
import { Route, Switch, Redirect, BrowserRouter, HashRouter } from "react-router-dom";
import { AccountRoutes } from "./account";
import { HomeRoutes } from "./home";
import { VideoRoutes } from "./video";

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/home" render={() => <HomeRoutes />} />
                <Route path="/account" render={() => <AccountRoutes />} />
                <Redirect from='/admin/login' to={`/account/login`} />
                <Redirect from='/' to={`/account/login`} />
            </Switch>
        </BrowserRouter>
    )
}
export { Routes };