import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import typeBg from './image/typeBg.jpg'
import dataBg from './image/dataBg.png'
import AutoScroll from '@brianmcallister/react-auto-scroll'
import deviceIcon from './image/device.png'
import { StatisticCommon, StatisticCommonInterface } from 'http-request/tokiot/statistic/common'
import { Icon } from 'components/Icon/icon'
import { InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
import { Title } from '../../title'
import { MenuType } from 'routes/home'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
  features: MenuType,
}
interface IState {
  deviceTypeList: Array<StatisticCommonInterface.DeviceTypeInfo & { icon: string }>,
  showList: Array<StatisticCommonInterface.DeviceTypeInfo & { icon: string }>,
  showIndex: number,
  showCount: number,
}
class DeviceType extends PureComponent<IProps, IState> {
  state: IState = {
    deviceTypeList: [],
    showList: [],
    showIndex: 0,
    showCount: 6
  }
  timer?: NodeJS.Timeout = undefined;
  componentDidMount() {
    this.getDeviceType()
    this.startTimer()
  }

  componentDidUpdate(prevProps: IProps) {
    const { areaInfo, features } = this.props
    if (CommonParameter.isChange(prevProps.areaInfo, areaInfo) || features !== prevProps.features) {
      this.getDeviceType()
    }
  }

  componentWillUnmount() {
    this.stopTimer()
  }
  startTimer = () => {
    this.timer = setInterval(this.indexAdd, 4000)
  }

  stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  indexAdd = () => {
    let { deviceTypeList, showList, showIndex, showCount } = this.state
    if (
      !(
        deviceTypeList &&
        deviceTypeList.length > 0 &&
        deviceTypeList.length >= showCount
      )
    )
      return
    if (showIndex + 1 >= deviceTypeList.length) {
      showIndex = 0
    } else {
      showIndex += 1
    }
    showList.shift()
    showList.push(deviceTypeList[showIndex])
    this.setState({ deviceTypeList, showList, showIndex })
  }

  getDeviceType = () => {
    const { areaInfo, features } = this.props;
    const { showCount } = this.state;
    if (features === `lakeside`) {
      let typeList: Array<StatisticCommonInterface.DeviceTypeInfo & { icon: string }> = [
        {
          typeCode: `智能停车位`,
          typeName: `智能停车位`,
          total: `669`,
          normalTotal: `659`,
          alarmTotal: `5`,
          offlineTotal: `5`,
          loseTotal: `0`,
          notActiveTotal: `0`,
          icon: `zhj-yongdian-copy`,
        },
        {
          typeCode: `智慧用电`,
          typeName: `智慧用电`,
          total: `306`,
          normalTotal: `300`,
          alarmTotal: `1`,
          offlineTotal: `5`,
          loseTotal: `0`,
          notActiveTotal: `0`,
          icon: `zhj-yongdian-copy`,
        },
        {
          typeCode: `智能音柱`,
          typeName: `智能音柱`,
          total: `19`,
          normalTotal: `16`,
          alarmTotal: `1`,
          offlineTotal: `2`,
          loseTotal: `0`,
          notActiveTotal: `0`,
          icon: `zhj-yongdian-copy`,
        },
        {
          typeCode: `智慧垃圾分类`,
          typeName: `智慧垃圾分类`,
          total: `186`,
          normalTotal: `183`,
          alarmTotal: `1`,
          offlineTotal: `2`,
          loseTotal: `0`,
          notActiveTotal: `0`,
          icon: `zhj-yongdian-copy`,
        }
      ]
      typeList.sort((f1, f2) => Number(f2.total) - Number(f1.total));
      let showList = typeList.filter((f1, index) => index < showCount)
      this.setState({
        deviceTypeList: typeList,
        showList,
        showIndex: showCount - 1
      })
      return;
    }
    StatisticCommon.DeviceType({ areaCode: areaInfo.code }).then(ret => {
      if (ret && ret.length > 0) {
        let typeList = ret.map(f1 => {
          let icon = ``;
          switch (f1.typeCode) {
            case `ZHYD`:
              icon = `zhj-yongdian-copy`
              break
            case `ZHYS`:
              icon = `zhj-yongshui`
              break
            case `SPJK`:
              icon = `zhj-jiankong-copy-copy`
              break
            case `ZNYJ`:
              icon = `zhj-yujing-copy`
              break
            case `CDZ`:
              icon = `zhj-chongdianzhuang-copy`
              break
            case `YG`:
              icon = `zhj-yongdian-copy`
              break
            case `YX`:
              icon = `zhj-yongdian-copy`
              break
            default:
              icon = `zhj-yongdian-copy`
              break
          }
          return { ...f1, icon }
        })
        typeList.sort((f1, f2) => Number(f2.total) - Number(f1.total));
        let showList = typeList.filter((f1, index) => index < showCount)
        this.setState({
          deviceTypeList: typeList,
          showList,
          showIndex: showCount - 1
        })
      } else {
        this.setState({ deviceTypeList: [], showList: [] })
      }
    })
  }

  getTypeItem = (item: StatisticCommonInterface.DeviceTypeInfo & { icon: string }) => {
    const { features } = this.props;
    return (
      <div key={item.typeCode} className={styles.typeItem}>
        <div className={styles.iconDiv}>
          <img className={styles.image} src={typeBg} alt="" />
          <Icon iot className={styles.icon} type={item.icon} />
        </div>
        <div className={styles.nameDiv}>
          <div className={styles.text}>{item.typeName}</div>
          <div className={styles.total}>
            {Number(item.total).toLocaleString()}
          </div>
        </div>
        <div className={styles.stateList}>
          <div className={styles.stateDiv}>
            <img className={styles.image} src={dataBg} alt="" />
            <div className={styles.text}>{`正常`}</div>
            <div className={styles.total}>{item.normalTotal}</div>
          </div>
          <div className={styles.stateDiv}>
            <img className={styles.image} src={dataBg} alt="" />
            <div className={styles.text}>{features === `lakeside` ? `故障` : `告警`}</div>
            <div className={classnames(styles.total, styles.red)}>
              {item.alarmTotal}
            </div>
          </div>
          <div className={styles.stateDiv}>
            <img className={styles.image} src={dataBg} alt="" />
            <div className={styles.text}>{`离线`}</div>
            <div className={classnames(styles.total, styles.gray)}>
              {Number(item.offlineTotal) +
                Number(item.loseTotal) +
                Number(item.notActiveTotal)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { features } = this.props
    const { showList } = this.state

    return (
      <div className={styles.deviceTypeMain}>
        <Title icon={deviceIcon}>{features === `lakeside` ? `民生应用` : `设备类型统计`}</Title>
        <AutoScroll
          height={425 * CommonParameter.FontSize}
          preventInteraction={true}
          scrollBehavior={`smooth`}
          showOption={false}
        >
          {showList.map(f1 => this.getTypeItem(f1))}
        </AutoScroll>
      </div>
    )
  }
}

export { DeviceType }
