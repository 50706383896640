import React, { PureComponent } from 'react'
import styles from './index.module.less'
import $$ from 'cmn-utils'
import videoIcon from './image/video.png'

class VideoTop extends PureComponent {
  constructor (props) {
    super(props)

    let key = this.getCacheKey()
    const buttonSelectState = $$.getStore(key)
    this.state = buttonSelectState || {
      selelcButton: 1
    }
  }

  componentDidMount () {}

  saveState = () => {
    let key = this.getCacheKey()
    $$.setStore(key, this.state)
  }

  getCacheKey = () => {
    let cacheKey = 'buttonSelectState'
    // 如果能取到用户名，则拼接用户名作为缓存的key
    let headers = $$.getStore('headers')
    if (headers && headers.Username && headers.Username.length > 0) {
      cacheKey = cacheKey + '-' + headers.Username
    }
    return cacheKey
  }

  getSwitchButton = item => {
    const { selelcButton } = this.state
    let style
    if (item.count === 1) {
      style =
        selelcButton === item.count
          ? styles.selectSwitch1
          : styles.unselectSwitch1
    } else if (item.count === 4) {
      style =
        selelcButton === item.count
          ? styles.selectSwitch4
          : styles.unselectSwitch4
    } else {
      style =
        selelcButton === item.count
          ? styles.selectSwitch8
          : styles.unselectSwitch8
    }
    return (
      <div
        className={style}
        onClick={e => this.onSwitchButtonClick(item.count)}
      />
    )
  }

  onSwitchButtonClick = count => {
    const { onSetGridCount } = this.props
    onSetGridCount(count)
    this.setState({ selelcButton: count }, this.saveState)
  }

  render () {
    let switchButtons = [
      {
        count: 1
      },
      {
        count: 4
      },
      {
        count: 8
      }
    ]
    return (
      <div className={styles.topMain}>
        <div className={styles.titleRoot}>
          <img className={styles.image} src={videoIcon} />
          <span className={styles.title}>视频监控平台</span>
        </div>
        <div className={styles.switchRoot}>
          {switchButtons.map(item => this.getSwitchButton(item))}
        </div>
      </div>
    )
  }
}

export default VideoTop
