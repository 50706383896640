import React, { PureComponent } from 'react'
import styles from './index.module.less'
import classnames from 'classnames'
import top1 from './image/1.png'
import top2 from './image/2.png'
import top3 from './image/3.png'
import orgIcon from './image/org.png'
import { Statistic, StatisticInterface } from 'http-request/tokiot/statistic'
import { InfoInterface } from 'http-request/tokiot/info'
import { CommonParameter } from 'utils/common-parameter'
import { Title } from '../../title'
import { ColumnsItem, DataTable } from '../../data-table'
interface IProps {
  areaInfo: InfoInterface.AreaInfo,
}
interface IState {
  unitList: Array<StatisticInterface.OrganInfo>
}
class UnitRank extends PureComponent<IProps, IState> {
  state: IState = {
    unitList: [],
  }

  componentDidMount() {
    this.getUnitRank();
  }

  componentDidUpdate(prevProps: IProps) {
    const { areaInfo } = this.props;
    if (CommonParameter.isChange(prevProps.areaInfo, areaInfo)) {
      this.getUnitRank();
    }
  }

  getUnitRank = () => {
    const { areaInfo } = this.props;
    Statistic.Organ({
      areaCode: areaInfo.code,
      enableAlarm: true,
      enableApi: true,
      enableDeviceType: true,
    }).then(ret => {
      if (ret && ret.length > 0) {
        ret.push({
          id: `1`,
          createdTime: `string`,
          updatedTime: `string`,
          fullName: `string`,
          simpleName: `初运工程`,
          code: `string`,
          address: `string`,
          principal: `string`,
          tel: `string`,
          remark: `string`,
          platformList: [],
        });
        ret.push({
          id: `1`,
          createdTime: `string`,
          updatedTime: `string`,
          fullName: `string`,
          simpleName: `泰德食品`,
          code: `string`,
          address: `string`,
          principal: `string`,
          tel: `string`,
          remark: `string`,
          platformList: [],
        });
        ret.push({
          id: `1`,
          createdTime: `string`,
          updatedTime: `string`,
          fullName: `string`,
          simpleName: `昭市电子`,
          code: `string`,
          address: `string`,
          principal: `string`,
          tel: `string`,
          remark: `string`,
          platformList: [],
        });
        ret.push({
          id: `1`,
          createdTime: `string`,
          updatedTime: `string`,
          fullName: `string`,
          simpleName: `正运电子`,
          code: `string`,
          address: `string`,
          principal: `string`,
          tel: `string`,
          remark: `string`,
          platformList: [],
        });
        ret.push({
          id: `1`,
          createdTime: `string`,
          updatedTime: `string`,
          fullName: `string`,
          simpleName: `中光矿业`,
          code: `string`,
          address: `string`,
          principal: `string`,
          tel: `string`,
          remark: `string`,
          platformList: [],
        });
        ret.push({
          id: `1`,
          createdTime: `string`,
          updatedTime: `string`,
          fullName: `string`,
          simpleName: `天鑫科技`,
          code: `string`,
          address: `string`,
          principal: `string`,
          tel: `string`,
          remark: `string`,
          platformList: [],
        });


        this.setState({ unitList: ret });
      } else {
        this.setState({ unitList: [] });
      }
    });
  }

  render() {
    const { unitList } = this.state;
    let showList = unitList;
    let columns: Array<ColumnsItem<StatisticInterface.OrganInfo>> = [
      {
        key: `index`,
        name: `排名`,
        width: `50rem`,
        center: true,
        render: (text, record, index) => {
          let data;
          switch (index) {
            case 0:
              data = (<img className={styles.image} src={top1} alt="" />)
              break;
            case 1:
              data = (<img className={styles.image} src={top2} alt="" />)
              break;
            case 2:
              data = (<img className={styles.image} src={top3} alt="" />)
              break;
            default:
              data = (<div className={styles.topText}>{`TOP${index + 1}`}</div>)
              break;
          }
          return data;
        }
      },
      {
        key: `simpleName`,
        name: `运营机构`,
        render: (text, record, index) => {
          const platform = record.platformList[0];
          if (platform) {
            let href = platform.webUrl;
            let params = JSON.stringify({ account: platform.webAccount, password: platform.webPassword })
            let count = 2;
            while (count >= 0) {
              params = window.btoa(params);
              count--;
            }
            href = href + "?" + params;
            return (
              <a className={styles.text} target='_blank' href={href}>{text}</a>
            )
          }
          return (
            <a className={styles.text}>{text}</a>
          )
        }
      },
      {
        key: `deviceTotal`,
        name: `联网设备`,
        width: `65rem`,
        center: true,
        render: (text, item, index) => {
          let total = 0;
          if (item.platformList && item.platformList.length > 0) {
            item.platformList.forEach(record => {
              if (record.deviceTypeStatistic && record.deviceTypeStatistic.length > 0) {
                record.deviceTypeStatistic.forEach(f1 => total += Number(f1.total));
              }
            })
          }
          return (
            <div className={styles.text}>{total}</div>
          )
        }
      },
      {
        key: `handlingAlarmRatio`,
        name: `处警率`,
        width: `55rem`,
        center: true,
        render: (text, item, index) => {
          let ratio = 1;
          if (item.platformList && item.platformList.length > 0) {
            item.platformList.forEach(record => {
              if (record.alarmStatistic && record.alarmStatistic.handleTotal && record.alarmStatistic.unHandleTotal) {
                let total = Number(record.alarmStatistic.handleTotal) + Number(record.alarmStatistic.unHandleTotal);
                if (total > 0) {
                  ratio = Number(record.alarmStatistic.handleTotal) / total;
                }
              }

            })
          }
          return (
            <div className={styles.text}>{Math.round(Number(ratio) * 100)}%</div>
          )
        }
      },
      {
        key: `apiRatio`,
        name: `接口稳定`,
        width: `65rem`,
        center: true,
        render: (text, item, index) => {
          let ratio = 0;
          if (item.platformList && item.platformList.length > 0) {
            item.platformList.forEach(record => {
              if (record.apiStatistic && record.apiStatistic.total && record.apiStatistic.successTotal) {
                if (Number(record.apiStatistic.total) > 0) {
                  ratio = Number(record.apiStatistic.successTotal) / Number(record.apiStatistic.total);
                }
              }
            })
          }
          return (
            <div className={styles.text}>{Math.round(Number(ratio) * 100)}%</div>
          )
        }
      },
    ]
    return (
      <div className={styles.unitRankMain}>
        <Title icon={orgIcon}>运营机构排名</Title>
        <div className={styles.body}>
          <DataTable columns={columns} data={showList} />
        </div>
      </div>
    );
  }
}

export { UnitRank };
